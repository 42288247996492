import {
  Button,
  Card,
  Col,
  Divider,
  message,
  Row,
  Select,
  Spin,
  Modal,
  Input,
  Form,
  DatePicker,
} from "antd";
import React, { Component } from "react";
import CustomLayout from "../../components/CustomLayout";
import { Spacer } from "../../components/global";
import { LeftOutlined } from "@ant-design/icons";
import {
  getMedicalFolderPhoto,
  getProfile,
  getUser,
  sendEmailUser,
  updateUserType,
} from "../../services/users.services";
import Moment from "react-moment";
import {
  addOrganisationAdmin,
  addOrganisationUser,
  deleteOrganisationUser, exportOnUser, reformatCsv,
} from "../../services/organisations.services";

const { Option } = Select;
const { confirm } = Modal;

class DetailOrgaUser extends Component {
  state = {
    loaded: false,
    newRole: -1,
    userType: 2,
    userId: '',
    user_id: ''
  };

  componentDidMount() {
    this.fetchProfile();
    this.fetchUser();
  }

  fetchProfile() {
    this.setState({ user_id: this.props.match.params.id });
    getProfile().then((res) => {
      this.setState({
        userType: parseInt(res?.user.type_compte),
        userId: res?.user.id,
      });
    });

    getMedicalFolderPhoto(4).then((img) => this.setState({ avatar: img }));
  }

  fetchUser() {
    const { user_id } = this.props.match.params;
    console.log(this.props);
    getUser(user_id).then((res) => {
      const { user } = res;
      console.log(user);
      this.setState({ user, loaded: true });
    });
  }

  // Check if user is admin of organisation or not...
  checkRole() {
    const { user } = this.state;
    const { organisation_id } = this.props.match.params;

    const admin_orga = user?.organisations_associees?.find(
      (organisation) =>
        organisation.id_organisation === organisation_id &&
        organisation.administrateur_organisation
    );

    return admin_orga !== undefined;
  }

  // switch roles here...
  switchRole() {
    const { user, newRole } = this.state;
    const { organisation_id } = this.props.match.params;

    if (newRole) addOrganisationAdmin(organisation_id, user?.id);
    else
      deleteOrganisationUser(organisation_id, user.id).then((_) =>
        addOrganisationUser(organisation_id, user?.id)
      );

    this.fetchUser();
    message.success("Rôle modifié avec succès");
    this.setState({ newRole: -1 });
  }

  // Changer de type de compte
  switchType() {
    const { user, newType } = this.state;

    const data = {
      type_compte: newType,
      actif: user?.actif,
      service: user?.service,
    };
    if (newType)
      updateUserType(user?.id, data).then((res) => {
        console.log("newType", res);
      });
  }

  // delete user...
  deleteUser() {
    const { user } = this.state;
    const { organisation_id } = this.props.match.params;
    deleteOrganisationUser(organisation_id, user.id).then((_) => {
      this.props.history.goBack();
      message.success("Utilisateur dissocié de l'organisation avec succès");
    });
  }

  sendEmail() {
    // const organisation_id = this.props.match.params.organisation_id;
    const user_id = this.props.match.params.user_id;

    var message_content = "";

    const onOk = () => {
      if (message_content.length) {
        // console.log(message_content, user_id);
        sendEmailUser(user_id, message_content).then((res) => {
          console.log(res);
          message.success("Message envoyé par email avec succès");
        });
      } else
        message.error(
          "Une erreur est survenue: Vous n'avez entré aucun message"
        );
    };

    confirm({
      content: (
        <div>
          <h2>Envoyer un email à l'utilisareur</h2>
          <Divider />

          <Input.TextArea
            size="large"
            placeholder="Votre message"
            onInput={(e) => (message_content = e.target.value)}
          />
          <Divider />
        </div>
      ),
      icon: <span></span>,
      onOk,
      okText: "Envoyer",
      onCancel() {
        console.log("canceled");
      },
      width: 1000,
    });
  }

  render() {
    const { user, loaded, newRole, newType } = this.state;
    return (
      <CustomLayout {...this.props} selectedKey="organisations">
        {loaded ? (
          <>
            <Row justify="start" align="middle" gutter={[6, 12]}>
              <Button
                shape="circle"
                icon={<LeftOutlined />}
                onClick={() => this.props.history.goBack()}
              />
              <h1 style={{ margin: 0, marginLeft: 20 }}>
                Utilisateur {user?.id_helpp_life}
              </h1>
            </Row>
            <Divider />
            <Row gutter={16}>
              <Col>
                <Card style={{ width: 400 }}>
                  <h3>Information personnelles</h3>
                  <Row>
                    <Col span={12}>
                      <p>
                        <b>Nom:</b>
                        <br />
                        {user?.nom}
                      </p>
                    </Col>
                    <Col span={12}>
                      <p>
                        <b>Prénom:</b>
                        <br />
                        {user?.prenom}
                      </p>
                    </Col>
                    <Col span={24}>
                      <p>
                        <b>Date de naissance:</b>
                        <br />
                        <Moment format="DD/MM/YYYY">
                          {user?.date_naissance}
                        </Moment>
                      </p>
                    </Col>
                    <Col span={24}>
                      <Divider>
                        <Button onClick={() => this.sendEmail()} block>
                          Envoyer un message
                        </Button>
                      </Divider>
                    </Col>
                  </Row>
                </Card>
              </Col>
              <Col>
                <Card style={{ width: 400 }}>
                  <h3>Service de l'utilisateur</h3>
                  <Form
                    onFinish={(values) => {
                      const { service } = values;
                      const { actif, type_compte } = user;

                      const data = {
                        service: service ?? user?.service,
                        actif,
                        type_compte: newType ?? type_compte,
                      };

                      updateUserType(user?.id, data).then((res) => {
                        console.log(res);
                        message.success("Utilisateur modifié avec succès");
                        this.fetchUser();
                      });
                    }}
                  >
                    <Form.Item name="service">
                      <Input
                        defaultValue={user?.service}
                        placeholder="Entrer un service"
                      />
                    </Form.Item>
                    <Divider />
                    <center>
                      <h4>Type de compte</h4>

                      <Select
                        style={{ width: 200 }}
                        defaultValue={user?.type_compte}
                        onChange={(newType) => this.setState({ newType })}
                        disabled={this.state.userType === 2 || this.state.userId === user?.id}
                      >
                        <Option value={"2"}>Utilisateur</Option>
                        <Option value={"31"}>Pompier (Opérateur)</Option>
                        <Option value={"32"}>Pompier (Véhicule)</Option>
                        <Option value={"41"}>SAMU (Opérateur)</Option>
                        <Option value={"42"}>SAMU (Véhicule)</Option>
                        <Option value={"51"}>Forces de l'ordre (Opérateur)</Option>
                        <Option value={"52"}>Forces de l'ordre (Véhicule)</Option>
                      </Select>
                    </center>
                    <Divider />
                    <Form.Item>
                      <Button block type="primary" htmlType="submit">
                        Enregistrer
                      </Button>
                    </Form.Item>
                  </Form>
                </Card>
              </Col>
            </Row>
            <Spacer />
            <Row gutter={16}>
              <Col>
                <Card style={{ width: 400 }}>
                  <center>
                    <h4>Droits d'accès</h4>
                    <Select
                      style={{ width: 200 }}
                      defaultValue={this.checkRole.bind(this)}
                      onChange={(newRole) => this.setState({ newRole })}
                    >
                      <Option value={true}>Administrateur</Option>
                      <Option value={false}>Utilisateur</Option>
                    </Select>
                    <Divider />
                    <Button
                      block
                      type="primary"
                      onClick={this.switchRole.bind(this)}
                      disabled={newRole < 0}
                    >
                      Enregistrer
                    </Button>
                    <Spacer />
                    <Button
                      block
                      danger
                      size="small"
                      onClick={this.deleteUser.bind(this)}
                    >
                      Supprimer l'utilisateur de l'organisation
                    </Button>
                  </center>
                </Card>
              </Col>
              <Col>
                <Card
                  style={{ width: 400 }}
                  title="Veuillez sélectionner une période"
                >
                  <DatePicker.RangePicker
                      style={{ width: "100%" }}
                      size="large"
                      onChange={(dates, dateStrings) => {
                        if (dates) {
                          this.setState({
                            exportFrom:  dateStrings[0].replaceAll('-', ''),
                            exportTo:  dateStrings[1].replaceAll('-', '')
                          });
                        }
                      }}
                  />
                  <Spacer />

                  <Button block size="large" onClick={async () => {
                    if (this.state.exportFrom && this.state.exportTo) {
                      let exports = await exportOnUser([user?.id_helpp_life], this.state.exportFrom, this.state.exportTo);
                      exports = reformatCsv(exports.toString());
                      const BOM = new Uint8Array([0xEF,0xBB,0xBF]);
                      let href = URL.createObjectURL(new Blob([BOM, exports],{type:'text/csv;charset=UTF-8', encoding:"UTF-8"}));
                      const link = document.createElement('a');
                      link.href = href;
                      link.download = `export-${user?.id_helpp_life}-${this.state.exportFrom}-${this.state.exportTo}.csv`;
                      link.target = '_blank';
                      link.click();
                    }
                  }}>
                    Export QR codes
                  </Button>
                </Card>
              </Col>
            </Row>
          </>
        ) : (
          <div
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          >
            <Spin />
          </div>
        )}
      </CustomLayout>
    );
  }
}

export default DetailOrgaUser;
