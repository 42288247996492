import React, { Component } from "react";
import {
  Divider,
  Button,
  Row,
  Card,
  Col,
  Table,
  Input,
  DatePicker,
  Select,
  Checkbox,
  message,
} from "antd";
import CustomLayout from "../../components/CustomLayout";
import { Spacer } from "../../components/global";
import { LeftOutlined, DeleteOutlined } from "@ant-design/icons";
import {
  editMedicalFolder,
  getMedicalFolder,
} from "../../services/users.services";
import Moment from "react-moment";

const { Option } = Select;

class MedicalPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      searchValue: "",
      enabledBalise: false,
      tmpName: "",
      tmpDate: undefined,
      tmpDateEnd: undefined,
      lastTmpDateEnd: undefined,
      inputIndex: -1,
    };
  }

  componentDidMount() {
    this.setState({ user_id: this.props.match.params.id });
    getMedicalFolder().then((res) => {
      console.log("medical_folder", res);
      this.setState({ dossier_medical: res?.dossier_medical });
    });
  }

  handleEdit() {
    var dossier_edited = this.state.dossier_medical;
    console.log("envoi", dossier_edited);
    editMedicalFolder(dossier_edited).then((res) => {
      console.log("edit", res);
      message.success("Dossier médical modifié avec succès");
    });
  }

  changeStart(_, dateString) {
    console.log(dateString);
    this.setState({ tmpDate: dateString + "T15:04:05Z" });
  }

  changeEnd(_, dateString) {
    console.log(dateString);
    this.setState({
      tmpDateEnd: dateString + "T15:04:05Z",
      lastTmpDateEnd: dateString + "T15:04:05Z",
    });
  }

  showAddInput(index, destination) {
    const { tmpName, tmpDate, tmpDateEnd, dossier_medical } = this.state;
    if (this.state.inputIndex === index) {
      var tmp = dossier_medical;

      switch (destination) {
        case "maladies":
          var maladies_row = {
            date_fin_maladie: tmpDateEnd,
            date_maladie: tmpDate,
            id_dossier_medical: dossier_medical?.id,
            nom_maladie: tmpName,
            uuid: "",
          };
          tmp?.maladies?.push(maladies_row);
          break;

        case "hospitalisations":
          var hospi_row = {
            date_fin_hospitalisation: tmpDateEnd,
            date_hospitalisation: tmpDate,
            id_dossier_medical: dossier_medical?.id,
            nom_hospitalisation: tmpName,
            uuid: "",
          };
          tmp?.hospitalisations?.push(hospi_row);
          break;

        case "allergies":
          var allergies_row = {
            id_dossier_medical: dossier_medical?.id,
            nom_allergie: tmpName,
            uuid: "",
          };
          tmp?.allergies?.push(allergies_row);
          break;

        case "traitements":
          var traitements_row = {
            id_dossier_medical: dossier_medical?.id,
            description_traitement_en_cours: tmpName,
            uuid: "",
          };
          tmp?.traitement_en_cours?.push(traitements_row);
          break;

        case "antecedents":
          var antecedents_row = {
            date_antecedent: tmpDate,
            id_dossier_medical: dossier_medical?.id,
            description_antecedent: tmpName,
            uuid: "",
          };
          tmp?.antecedents?.push(antecedents_row);
          break;

        default:
          break;
      }

      this.setState({
        inputIndex: -1,
        tmpName: "",
      });
    } else {
      this.setState({ inputIndex: index });
    }
  }

  renderAddInput(index) {
    const { inputIndex } = this.state;
    return (
      <>
        <Row gutter={8} style={{ display: index !== inputIndex && "none" }}>
          <Col span={8}>
            <small>Nom</small>
            <Input
              onInput={(e) => this.setState({ tmpName: e.target.value })}
              value={this.state.tmpName}
              placeholder="Nom"
            />
          </Col>
          <Col
            span={8}
            style={{
              display: (inputIndex === 1 || inputIndex === 4) && "none",
            }}
          >
            <small>Début</small>
            <DatePicker
              // format="DD/MM/YYYY"
              style={{ width: "100%" }}
              onChange={this.changeStart.bind(this)}
            />
          </Col>
          <Col
            span={8}
            style={{
              display:
                (inputIndex === 1 || inputIndex === 4 || inputIndex === 3) &&
                "none",
            }}
          >
            <small>Fin</small>

            <DatePicker
              // format="DD/MM/YYYY"
              style={{
                width: "100%",
              }}
              disabled={this.state.tmpDateEnd === null}
              onChange={this.changeEnd.bind(this)}
            />
            <Checkbox
              style={{ marginTop: "5px" }}
              onChange={(e) => {
                if (e.target.checked) this.setState({ tmpDateEnd: null });
                else this.setState({ tmpDateEnd: this.state.lastTmpDateEnd });
              }}
            >
              Traitement en cours
            </Checkbox>
          </Col>
        </Row>
        <Spacer height="10px" />
        <Spacer height="10px" />
      </>
    );
  }

  render() {
    const columns = [
      {
        title: "",
        dataIndex: "index",
        key: "index",
        hidden: true,
      },
      {
        title: "Nom",
        dataIndex: "name",
        key: "name",
      },
      {
        title: "Début",
        dataIndex: "start",
        key: "start",
      },
      {
        title: "Fin",
        dataIndex: "end",
        key: "end",
      },
      {
        title: "",
        dataIndex: "action",
        key: "action",
        width: "80px",
        render: (_, record) => {
          return (
            <Button
              danger
              type="primary"
              onClick={() => {
                var tmp = this.state.dossier_medical;
                tmp.maladies.splice(record.index, 1);
                this.setState({ dossier_medical: tmp });
              }}
            >
              <DeleteOutlined />
            </Button>
          );
        },
      },
    ].filter((v) => !v.hidden);

    const columnsAlt = [
      {
        title: "",
        dataIndex: "index",
        key: "index",
        hidden: true,
      },
      {
        title: "Nom",
        dataIndex: "name",
        key: "name",
      },
      {
        title: "Début",
        dataIndex: "start",
        key: "start",
      },
      {
        title: "Fin",
        dataIndex: "end",
        key: "end",
      },
      {
        title: "",
        dataIndex: "action",
        key: "action",
        width: "80px",
        render: (_, record) => {
          return (
            <Button
              danger
              type="primary"
              onClick={() => {
                var tmp = this.state.dossier_medical;
                tmp.hospitalisations.splice(record.index, 1);
                this.setState({ dossier_medical: tmp });
              }}
            >
              <DeleteOutlined />
            </Button>
          );
        },
      },
    ].filter((v) => !v.hidden);

    const namedCol = [
      {
        title: "",
        dataIndex: "index",
        key: "index",
        hidden: true,
      },
      {
        title: "Nom",
        dataIndex: "nom",
        key: "nom",
      },
      {
        title: "",
        dataIndex: "action",
        key: "action",
        width: "80px",
        render: (_, record) => {
          return (
            <Button
              danger
              type="primary"
              onClick={() => {
                var tmp = this.state.dossier_medical;
                tmp.allergies.splice(tmp.allergies.indexOf(record), 1);

                this.setState({ dossier_medical: tmp });
              }}
            >
              <DeleteOutlined />
            </Button>
          );
        },
      },
    ].filter((v) => !v.hidden);

    const namedColAlt = [
      {
        title: "",
        dataIndex: "index",
        key: "index",
        hidden: true,
      },
      {
        title: "Nom",
        dataIndex: "nom",
        key: "nom",
      },
      {
        title: "",
        dataIndex: "action",
        key: "action",
        width: "80px",
        render: (_, record) => {
          return (
            <Button
              danger
              type="primary"
              onClick={() => {
                var tmp = this.state.dossier_medical;
                tmp.traitement_en_cours.splice(record.index, 1);
                this.setState({ dossier_medical: tmp });
              }}
            >
              <DeleteOutlined />
            </Button>
          );
        },
      },
    ].filter((v) => !v.hidden);

    const antCol = [
      {
        title: "",
        dataIndex: "index",
        key: "index",
        hidden: true,
      },
      {
        title: "Nom",
        dataIndex: "nom",
        key: "nom",
      },
      {
        title: "Date",
        dataIndex: "date",
        key: "date",
      },
      {
        title: "",
        dataIndex: "action",
        key: "action",
        width: "80px",
        render: (_, record) => {
          return (
            <Button
              danger
              type="primary"
              onClick={() => {
                var tmp = this.state.dossier_medical;
                tmp.antecedents.splice(tmp.antecedents.indexOf(record), 1);
                this.setState({ dossier_medical: tmp });
              }}
            >
              <DeleteOutlined />
            </Button>
          );
        },
      },
    ].filter((v) => !v.hidden);

    const { user, dossier_medical, inputIndex, tmpName } = this.state;

    return (
      <div>
        <CustomLayout {...this.props} selectedKey="dossier_medical">
          <Row justify="start" align="middle" gutter={[6, 12]}>
            <Button
              shape="circle"
              icon={<LeftOutlined />}
              onClick={() => this.props.history.goBack()}
            />
            <h1 style={{ margin: 0, marginLeft: 20 }}>
              Dossier Médical {user?.id_helpp_life}
            </h1>
          </Row>
          <Divider orientation="right">
            <Button type="primary" onClick={this.handleEdit.bind(this)}>
              Enregistrer
            </Button>
          </Divider>
          <Spacer />
          <Row gutter={[24, 12]}>
            <Col md={24} lg={24} xl={24}>
              <Card title={<h2 style={{ margin: 0 }}>Dossier médical</h2>}>
                <Row gutter={[24, 24]}>
                  <Col xs={24} sm={24} md={12}>
                    <Table
                      pagination={false}
                      scroll={{ x: 600, y: 300 }}
                      columns={columns}
                      dataSource={dossier_medical?.maladies.map(
                        (maladie, index) => {
                          return {
                            index: index,
                            name: maladie?.nom_maladie,
                            start: (
                              <Moment format="DD/MM/YYYY">
                                {maladie?.date_maladie}
                              </Moment>
                            ),
                            end:
                              maladie?.date_fin_maladie === null ? (
                                "En cours"
                              ) : (
                                <Moment format="DD/MM/YYYY">
                                  {maladie?.date_fin_maladie}
                                </Moment>
                              ),
                          };
                        }
                      )}
                      title={() => <h3 style={{ margin: 0 }}>Maladies</h3>}
                      bordered
                    />
                    <Spacer />
                    {this.renderAddInput(0)}
                    <Button
                      type={inputIndex === 0 && "primary"}
                      onClick={() => this.showAddInput(0, "maladies")}
                      style={{ float: "right" }}
                      disabled={
                        inputIndex === 0 &&
                        (!tmpName.length ||
                          !this.state.tmpDate?.length ||
                          (!this.state.tmpDateEnd?.length &&
                            this.state.tmpDateEnd !== null))
                      }
                    >
                      {inputIndex === 0 ? "Valider" : "Ajouter"}
                    </Button>
                  </Col>
                  <Col xs={24} sm={24} md={12}>
                    <Table
                      pagination={false}
                      scroll={{ x: 600, y: 300 }}
                      columns={namedCol}
                      dataSource={dossier_medical?.allergies?.map(
                        (allergie) => {
                          return {
                            key: allergie?.nom_allergie,
                            nom: allergie?.nom_allergie,
                          };
                        }
                      )}
                      title={() => <h3 style={{ margin: 0 }}>Allergies</h3>}
                      bordered
                    />
                    <Spacer />
                    {this.renderAddInput(1)}

                    <Button
                      type={inputIndex === 1 && "primary"}
                      onClick={() => this.showAddInput(1, "allergies")}
                      style={{ float: "right" }}
                      disabled={inputIndex === 1 && tmpName.length === 0}
                    >
                      {inputIndex === 1 ? "Valider" : "Ajouter"}
                    </Button>
                  </Col>
                  <Col xs={24} sm={24} md={12}>
                    <Table
                      pagination={false}
                      scroll={{ x: 600, y: 300 }}
                      columns={columnsAlt}
                      dataSource={dossier_medical?.hospitalisations?.map(
                        (hospitalisation, index) => {
                          return {
                            key: index + "hosp",
                            index: index,
                            name: hospitalisation?.nom_hospitalisation,
                            start: (
                              <Moment format="DD/MM/YYYY">
                                {hospitalisation?.date_hospitalisation}
                              </Moment>
                            ),
                            end:
                              hospitalisation?.date_fin_hospitalisation ===
                                null ||
                              hospitalisation?.date_fin_hospitalisation ===
                                "0001-01-01T00:00:00Z" ? (
                                "En cours"
                              ) : (
                                <Moment
                                  format="DD/MM/YYYY"
                                  onClick={() =>
                                    console.log(
                                      hospitalisation?.date_fin_hospitalisation
                                    )
                                  }
                                >
                                  {hospitalisation?.date_fin_hospitalisation}
                                </Moment>
                              ),
                          };
                        }
                      )}
                      title={() => (
                        <h3 style={{ margin: 0 }}>Hospitalisations</h3>
                      )}
                      bordered
                    />
                    <Spacer />
                    {this.renderAddInput(2)}

                    <Button
                      type={inputIndex === 2 && "primary"}
                      onClick={() => this.showAddInput(2, "hospitalisations")}
                      style={{ float: "right" }}
                      disabled={
                        inputIndex === 2 &&
                        (!tmpName.length ||
                          !this.state.tmpDate?.length ||
                          (!this.state.tmpDateEnd?.length &&
                            this.state.tmpDateEnd !== null))
                      }
                    >
                      {inputIndex === 2 ? "Valider" : "Ajouter"}
                    </Button>
                  </Col>

                  <Col xs={24} sm={24} md={12}>
                    <Table
                      pagination={false}
                      scroll={{ x: 600, y: 300 }}
                      columns={antCol}
                      dataSource={dossier_medical?.antecedents?.map(
                        (antecedent, index) => {
                          return {
                            key: index + "ant",
                            nom: antecedent?.description_antecedent,
                            date: (
                              <Moment format="DD/MM/YYYY">
                                {antecedent?.date_antecedent}
                              </Moment>
                            ),
                          };
                        }
                      )}
                      title={() => <h3 style={{ margin: 0 }}>Antécédents</h3>}
                      bordered
                    />
                    <Spacer />
                    {this.renderAddInput(3)}

                    <Button
                      type={inputIndex === 3 && "primary"}
                      onClick={() => this.showAddInput(3, "antecedents")}
                      style={{ float: "right" }}
                      disabled={
                        inputIndex === 3 &&
                        (!tmpName.length || !this.state.tmpDate?.length)
                      }
                    >
                      {inputIndex === 3 ? "Valider" : "Ajouter"}
                    </Button>
                  </Col>

                  <Col xs={24} sm={24} md={12}>
                    <Table
                      pagination={false}
                      scroll={{ x: 600, y: 300 }}
                      columns={namedColAlt}
                      dataSource={dossier_medical?.traitement_en_cours?.map(
                        (traitement, index) => {
                          return {
                            key: index + "traitment",
                            index: index,
                            nom: traitement?.description_traitement_en_cours,
                          };
                        }
                      )}
                      title={() => <h3 style={{ margin: 0 }}>Traitement</h3>}
                      bordered
                    />
                    <Spacer />
                    {this.renderAddInput(4)}

                    <Button
                      type={inputIndex === 4 && "primary"}
                      onClick={() => this.showAddInput(4, "traitements")}
                      style={{ float: "right" }}
                      disabled={inputIndex === 4 && tmpName.length === 0}
                    >
                      {inputIndex === 4 ? "Valider" : "Ajouter"}
                    </Button>
                  </Col>

                  <Col xs={24} sm={24} md={12}>
                    <Divider style={{ marginTop: 0 }} />

                    <h3>
                      Groupe Sanguin:&nbsp;
                      <Select
                        size="large"
                        key={dossier_medical?.groupe_sanguin}
                        defaultValue={dossier_medical?.groupe_sanguin}
                        style={{ width: 100 }}
                        onChange={(v) => {
                          var tmp = this.state.dossier_medical;
                          tmp.groupe_sanguin = v;
                          this.setState({ dossier_medical: tmp });
                        }}
                      >
                        <Option value="O+">O+</Option>
                        <Option value="O-">O-</Option>
                        <Option value="AB+">AB+</Option>
                        <Option value="AB-">AB-</Option>
                        <Option value="A+">A+</Option>
                        <Option value="A-">A-</Option>
                        <Option value="B+">B+</Option>
                        <Option value="B-">B-</Option>
                      </Select>
                    </h3>
                    <Divider />
                    <h3>Remarques</h3>
                    {/* <p style={{ height: 150, overflowY: "scroll" }}>
                      {dossier_medical?.remarque}
                    </p> */}
                    <Input.TextArea
                      value={dossier_medical?.remarque}
                      onInput={(e) => {
                        var tmp = this.state.dossier_medical;
                        tmp.remarque = e.target.value;
                        this.setState({ dossier_medical: tmp });
                      }}
                    />
                  </Col>

                  <Col xs={24} sm={24} md={12}>
                    <Divider style={{ marginTop: 0 }} />
                    <Checkbox
                        key={dossier_medical?.sourd_muet}
                        checked={dossier_medical?.sourd_muet}
                        onChange={(e) => {
                          var tmp = this.state.dossier_medical;
                          tmp.sourd_muet = e.target.checked;
                          this.setState({ dossier_medical: tmp });
                        }}
                    >
                      <h4 style={{ color: "blue", fontSize: "18px" }}>
                        <b>Ne peut pas parler</b>
                      </h4>
                    </Checkbox>
                    <Spacer height="1px" />
                    <span>Sourd/muet, ne parle pas Français ou toute situation ne permettant pas de communiquer oralement</span>

                    <Divider style={{ marginTop: 40 }} />
                    <h3>Handicaps</h3>

                    <Input.TextArea
                      value={dossier_medical?.handicap}
                      onInput={(e) => {
                        var tmp = this.state.dossier_medical;
                        tmp.handicap = e.target.value;
                        this.setState({ dossier_medical: tmp });
                      }}
                    />
                  </Col>

                  <Col xs={24} sm={24} md={12}>
                    <Divider style={{ marginTop: 0 }} />
                    <h3>Données publiques</h3>
                    <b style={{ fontSize: "16px", color: "red" }}>
                      Ces données seront accessibles publiquement aux personnes
                      qui scan votre TAG. Merci de ne dévoiler que des
                      informations utiles si vous avez un incident.
                    </b>
                    <Spacer height="20px" />
                    <Input.TextArea
                      style={{ fontSize: "16px", fontWeight: 700 }}
                      value={dossier_medical?.donnees_publiques}
                      onInput={(e) => {
                        var tmp = this.state.dossier_medical;
                        tmp.donnees_publiques = e.target.value;
                        this.setState({ dossier_medical: tmp });
                      }}
                    />
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </CustomLayout>
      </div>
    );
  }
}

export default MedicalPage;
