import { message } from "antd";
import axios from "axios";
import { API_URL, SOCIETE_URL } from "../config";
import { catchError } from "./response";

const getApiRequestService = (societeURL?: boolean) => {
  return axios.create({
    baseURL: `${societeURL ? SOCIETE_URL : API_URL}`,
    headers: {
      "Content-Type": "application/json",
      Authorization: ` ${
        localStorage.getItem("accessToken") !== null &&
        `Bearer ${localStorage.getItem("accessToken")}`
      }`,
    },
  });
};

// Exception pour edit une balise, Token Societe
/*
const getApiRequestServiceAlt = () => {
  return axios.create({
    baseURL: "https://api.societe.helpp-life.fr/api/societe",
    headers: {
      "Content-Type": "application/json",
      Authorization:
        "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6ImMwM2VmZWNkLWEzODMtNGFiOS05Mzc2LTRkZjI3MGQyY2E4ZSJ9.8Uz9OPG7VKC4628FypljCVtnpvY9MAwpkvJqCTyiEDY",
    },
  });
};
 */

export const getAllBalises = async () => {
  try {
    const res = await getApiRequestService().get(`/balises`);
    return res.data;
  } catch (error) {
    console.log("GET_ALL_BALISES", error);

    return catchError(error);
  }
};

export const getBalise = async (id: any) => {
  try {
    const res = await getApiRequestService().get(`/balises/${id}`);

    return res.data;
  } catch (error) {
    console.log("GET_BALISE", error);

    return catchError(error);
  }
};

export const editBalise = async (id: any, data: any) => {
  try {
    const res = await getApiRequestService().put(`/balises/${id}`, data);

    message.success("Balise modifiée avec succès");
    return res.data;
  } catch (error) {
    console.log("EDIT_BALISE", error);

    return catchError(error);
  }
};

export const getBaliseUser = async (id: any) => {
  try {
    const res = await getApiRequestService().get(`/balises/${id}/users`);

    return res.data;
  } catch (error) {
    console.log("GET_BALISE_USER", error);

    return catchError(error);
  }
};

export const setBaliseAlert = async (id: string, data: any) => {
  try {
    const res = await getApiRequestService(true).post(
      `/balises/${id}/mettre-en-alerte`,
      data
    );

    return res.data;
  } catch (error) {
    console.log("SET_BALISE_ALERT", error);

    return catchError(error);
  }
};

export const unsetBaliseAlert = async (id: any) => {
  try {
    const res = await getApiRequestService(true).post(
      `/balises/${id}/supprimer-en-alerte`
    );

    return res.data;
  } catch (error) {
    console.log("ARRETER_BALISE", error);

    return catchError(error);
  }
};

export const unsetBaliseGeoAlert = async (id: string) => {
  try {
    const res = await getApiRequestService(true).post(`balises/geostop/${id}`);

    return res.data;
  } catch (error) {
    console.log("SET_BALISE_GEO_ALERT", error);

    return catchError(error);
  }
};

export const getBaliseIdPerso = async (id: any) => {
  try {
    const res = await getApiRequestService().get(
      `/balises/update-id-perso/${id}`
    );

    return res.data;
  } catch (error) {
    console.log("GET_BALISE_ID_PERSO", error);

    return catchError(error);
  }
};

export const updateBaliseIdPerso = async (id: any, new_id_perso: string) => {
  try {
    const res = await getApiRequestService().put(
      `/balises/update-id-perso/${id}/${new_id_perso}`
    );

    return res.data;
  } catch (error) {
    console.log("UPDATE_BALISE_ID_PERSO", error);

    return catchError(error);
  }
};
