import React, { Component } from "react";
import {
  Divider,
  Button,
  Row,
  Card,
  Col,
  Table,
  Input,
  Form,
  Select,
  DatePicker,
  message,
  Tag,
  Spin,
  InputNumber,
  Upload,
  Image,
} from "antd";
import CustomLayout from "../../components/CustomLayout";
import { Spacer } from "../../components/global";
import {
  editProfile,
  getMedicalFolderPhoto,
  getProfile,
  updateProfilePicture,
} from "../../services/users.services";
import Moment from "react-moment";
import moment from "moment";
import styled from "styled-components";

class ProfilePage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      searchValue: "",
      enabledBalise: false,
      onEdit: false,
      loaded: false,
      newDate: "",
    };
  }

  componentDidMount() {
    this.fetchProfile();
  }

  fetchProfile() {
    this.setState({ user_id: this.props.match.params.id });
    getProfile().then((res) => {
      console.log("profile", res);
      this.setState({
        user: res?.user,
        loaded: true,
      });
    });

    getMedicalFolderPhoto(4).then((img) => this.setState({ avatar: img }));
  }

  onFinish(values) {
    const { user, newDate } = this.state;
    console.log("General:", values);

    if (this.state.avatar && this.state.avatarFile) {
      updateProfilePicture(this.state.avatarFile).then((res) => {
        console.log("uploadAvatar", res);
      });
    }

    if (values.adresse.adresse_numero < 1) {
      message.error(
        "Veuillez entrer une valeur correcte pour le numéro de rue."
      );
      return;
    }

    var new_profile = values;
    new_profile.date_naissance = values.date_naissance._i;

    if (newDate.length) new_profile.date_naissance = newDate;

    var output = [],
      item;

    if (values.contacts) {
      [0, 1, 2, 3, 4, 5, 6].forEach((index) => {
        item = {};
        item = values?.contacts[`${index}`];
        output.push(item);
      });
      new_profile.contacts = output;

      [0, 1, 2, 3, 4, 5, 6].forEach((index) => {
        new_profile.contacts[index].id = user?.contacts[index]?.id;
        new_profile.contacts[index].contact_index =
          user?.contacts[index]?.contact_index;
        new_profile.contacts[index].contact_pro = user?.contacts[index]?.contact_pro;
        new_profile.contacts[index].id_user = user?.contacts[index]?.id_user;
      });
    }

    new_profile.adresse.adresse_code_postal = Number(
      new_profile?.adresse.adresse_code_postal ?? 0
    );

    new_profile.adresse.adresse_numero = Number(
      new_profile?.adresse.adresse_numero ?? 0
    );

    new_profile.adresse.adresse_etage = Number(
      new_profile?.adresse.adresse_etage ?? 0
    );

    new_profile.adresse.adresse_numero_porte = Number(
      new_profile?.adresse.adresse_numero_porte ?? 0
    );

    console.log("New profile", new_profile);

    editProfile(new_profile).then((res) => {
      if (res.status) {
        message.error(res.error);
      } else {
        this.fetchProfile();
        this.setState({ onEdit: false }, () =>
          message.success("Informations modifiées avec succès")
        );
      }
    });
  }

  onDatePick(_, dateString) {
    const newDate = dateString + "T00:00:00Z";
    console.log(newDate);

    this.setState({ newDate });
  }

  beforeUpload(file) {

    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error("L'image doit être au format PNG ou JPEG");
      return false;
    }

    const isLt10M = file.size / 1024 / 1024 < 10;
    if (!isLt10M) {
      message.error("Le poids de l'image doit être inférieur à 10Mo");
      return false;
    }

    var avatarFile = new FormData();
    var avatar = URL.createObjectURL(file);
    avatarFile.set("photo", file);

    console.log(avatar);

    this.setState({ avatar, avatarFile });
  }

  render() {
    const { user, onEdit, loaded } = this.state;

    const columns = [
      {
        title: "ID Helpp-Life",
        dataIndex: "id_helpp_life",
        key: "id_helpp_life",
        hidden: true,
      },

      {
        title: "",
        dataIndex: "id",
        key: "id",
        hidden: true,
      },

      {
        title: "Nom",
        dataIndex: "nom",
        key: "nom",
        render: (text, record) => {
          return (
              <span>
                {text} {record.prenom}
            </span>
          );
        },
      },
      {
        title: "Prénom",
        dataIndex: "prenom",
        key: "prenom",
        hidden: true,
      },
      {
        title: "Téléphone",
        dataIndex: "telephone",
        key: "telephone",
        render: (text, record) => {
          return (
              <span>
                {text}
                {(record.contact_pro && text) && (
                    <Tag size="small" style={{marginLeft: 20}}>
                      Pro
                    </Tag>
                )}
            </span>
          );
        },
      },
      {
        title: "Pour la balise",
        dataIndex: "only_for_balise",
        key: "only_for_balise",
        render: (text, record) => {
          return (
              <span>
                {text ? user?.balises_associes.map((balise, index) => {
                  if (text === balise.id_balise) {
                    return <strong key={balise.id_balise}>{balise.ID_perso ? balise.ID_perso : text}</strong>;
                  }
                  else {
                    return null;
                  }
                }) : 'Toutes'}
            </span>
          );
        },
      },
    ].filter((item) => !item.hidden);

    return (
      <div>
        <CustomLayout {...this.props} selectedKey="profile">
          <Row justify="start" align="middle" gutter={[6, 12]}>
            <h1 style={{ margin: 0, marginLeft: 20 }}>
              Utilisateur {user?.id_helpp_life}
            </h1>
          </Row>
          <Divider orientation="right">
            <Button
              type={!onEdit && "primary"}
              danger={onEdit && "primary"}
              onClick={() =>
                this.setState(
                  { onEdit: !onEdit },
                  () => onEdit && this.fetchProfile()
                )
              }
            >
              {onEdit ? "Annuler" : "Modifier"}
            </Button>
          </Divider>
          <Spacer />
          {loaded ? (
            <>
              {!onEdit ? (
                <Row gutter={[24, 12]}>
                  <Col md={24} lg={12} xl={12}>
                    <Card title={<h2 style={{ margin: 0 }}>Détails</h2>}>
                      <Row align="bottom" justify="space-between">
                        <Col>
                          <p>
                            <b>Nom:</b> {user?.nom}
                            <br />
                            <b>Prénom:</b> {user?.prenom}
                            <br />
                            <b>Sexe:</b>{" "}
                            {user?.sexe > 1 ? "Féminin" : "Masculin"}
                            <br />
                            <b>Date de naissance:</b>{" "}
                            <Moment format="DD/MM/YYYY">
                              {user?.date_naissance}
                            </Moment>
                            <br />
                            <b>Téléphone:</b> {user?.telephone}
                            <br />
                          </p>
                        </Col>
                        <Col>
                          <br />
                          <Spacer height="5px" />
                          {this.state.avatar && (
                            <Image
                              src={this.state.avatar}
                              style={{ width: 150, height: 150, objectFit: 'cover'}}
                              alt="Aucune photo de profil"
                            />
                          )}
                        </Col>
                      </Row>
                      <p>
                        <Spacer />
                        <Divider
                          style={{ marginBottom: 0 }}
                          orientation="right"
                        >
                          <b>Adresse</b>
                        </Divider>
                        <br />
                        <b>Numéro:</b> {user?.adresse?.adresse_numero}
                        <br />
                        <b>Complément numéro:</b>{" "}
                        {user?.adresse?.adresse_complement_numero}
                        <br />
                        <b>Rue:</b> {user?.adresse?.adresse_rue}
                        <br />
                        <b>Complément de rue:</b>{" "}
                        {user?.adresse?.adresse_complement}
                        <br />
                        <b>Numéro de port:</b>{" "}
                        {user?.adresse?.adresse_numero_porte}
                        <br />
                        <b>Etage:</b> {user?.adresse?.adresse_etage}
                        <br />
                      </p>
                      <p>
                        <b>Code Postal:</b> {user?.adresse?.adresse_code_postal}
                        <br />
                        <b>Ville:</b> {user?.adresse?.adresse_ville}
                        <br />
                        <b>Pays:</b> {user?.adresse?.adresse_pays}
                        <br />
                      </p>
                      <p>
                        <b>Informations complémentaires:</b>
                        <br />
                        {user?.adresse?.adresse_points_remarquables}
                      </p>

                      <Divider
                          style={{ marginBottom: 0 }}
                          orientation="right"
                      >
                        <b>Service</b>
                      </Divider>

                      <strong>Offre : </strong> {user?.offre || 'N/C'}<br />
                      <strong>Date de fin d'abonnement : </strong> <Moment format="DD/MM/YYYY">{user?.date_fin_abonnement}</Moment><br />
                      <strong>Nombre d'alertes : </strong> {user?.nb_alerte}<br />
                    </Card>
                  </Col>
                  <Col md={24} lg={12} xl={12}>
                    <Card
                      title={
                        <h2 style={{ margin: 0 }}>Personnes à prévenir</h2>
                      }
                    >
                      <Table
                        columns={columns}
                        dataSource={[
                          {
                            nom: user?.contacts[0]?.nom !== "" ? user?.contacts[0]?.nom : "...",
                            prenom: user?.contacts[0]?.prenom,
                            telephone: user?.contacts[0]?.telephone,
                            contact_pro: user?.contacts[0]?.contact_pro,
                            only_for_balise: user?.contacts[0]?.only_for_balise,
                          },
                          {
                            nom: user?.contacts[1]?.nom !== "" ? user?.contacts[1]?.nom : "...",
                            prenom: user?.contacts[1]?.prenom,
                            telephone: user?.contacts[1]?.telephone,
                            contact_pro: user?.contacts[1]?.contact_pro,
                            only_for_balise: user?.contacts[1]?.only_for_balise,
                          },
                          {
                            nom: user?.contacts[2]?.nom !== "" ? user?.contacts[2]?.nom : "...",
                            prenom: user?.contacts[2]?.prenom,
                            telephone: user?.contacts[2]?.telephone,
                            contact_pro: user?.contacts[2]?.contact_pro,
                            only_for_balise: user?.contacts[2]?.only_for_balise,
                          },
                          {
                            nom: user?.contacts[3]?.nom !== "" ? user?.contacts[3]?.nom : "...",
                            prenom: user?.contacts[3]?.prenom,
                            telephone: user?.contacts[3]?.telephone,
                            contact_pro: user?.contacts[3]?.contact_pro,
                            only_for_balise: user?.contacts[3]?.only_for_balise,
                          },
                          {
                            nom: user?.contacts[4]?.nom !== "" ? user?.contacts[4]?.nom : "...",
                            prenom: user?.contacts[4]?.prenom,
                            telephone: user?.contacts[4]?.telephone,
                            contact_pro: user?.contacts[4]?.contact_pro,
                            only_for_balise: user?.contacts[4]?.only_for_balise,
                          },
                          {
                            nom: user?.contacts[5]?.nom !== "" ? user?.contacts[5]?.nom : "...",
                            prenom: user?.contacts[5]?.prenom,
                            telephone: user?.contacts[5]?.telephone,
                            contact_pro: user?.contacts[5]?.contact_pro,
                            only_for_balise: user?.contacts[5]?.only_for_balise,
                          },
                          {
                            nom: user?.contacts[6]?.nom !== "" ? user?.contacts[6]?.nom : "...",
                            prenom: user?.contacts[6]?.prenom,
                            telephone: user?.contacts[6]?.telephone,
                            contact_pro: user?.contacts[6]?.contact_pro,
                            only_for_balise: user?.contacts[6]?.only_for_balise,
                          },
                        ]}
                      />
                    </Card>
                  </Col>
                </Row>
              ) : (
                <Form
                  name="basic"
                  onFinish={(values) => this.onFinish(values)}
                  layout="vertical"
                  initialValues={{
                    prenom: user?.prenom,
                    nom: user?.nom,
                    sexe: user?.sexe,
                    telephone: user?.telephone,
                    date_naissance: moment(user?.date_naissance),
                    adresse: {
                      adresse_code_postal: parseInt(
                        user?.adresse?.adresse_code_postal
                      ),
                      adresse_complement: user?.adresse?.adresse_complement,
                      adresse_complement_numero:
                        user?.adresse?.adresse_complement_numero,
                      adresse_etage: parseInt(user?.adresse?.adresse_etage),
                      adresse_numero: parseInt(user?.adresse?.adresse_numero),
                      adresse_numero_porte: parseInt(
                        user?.adresse?.adresse_numero_porte
                      ),
                      adresse_pays: user?.adresse?.adresse_pays,
                      adresse_points_remarquables:
                        user?.adresse?.adresse_points_remarquables,
                      adresse_rue: user?.adresse?.adresse_rue,
                      adresse_ville: user?.adresse?.adresse_ville,
                    },
                    contacts: user?.contacts,
                  }}
                >
                  <Row gutter={[24, 12]}>
                    <Col md={24} lg={12} xl={12}>
                      <Card
                        title={
                          <h2 style={{ margin: 0 }}>
                            Modifier les informations
                          </h2>
                        }
                      >
                        <UploadWrapper>
                          <Upload
                            name="avatar"
                            listType="picture-card"
                            showUploadList={false}
                            beforeUpload={this.beforeUpload.bind(this)}
                          >
                            {this.state.avatar ? (
                              <img
                                src={this.state.avatar}
                                alt=""
                                style={{ height: 150, width: 150, objectFit: 'cover' }}
                              />
                            ) : (
                              <span>Ajouter une image</span>
                            )}
                          </Upload>
                          <em><small>Fichiers acceptés JPG (JPEG) et PNG jusqu'à 10Mo<br/> Les extensions doivent être en minuscule</small></em>
                        </UploadWrapper>

                        <Form.Item
                          label="Nom:"
                          name="nom"
                          rules={[
                            {
                              required: true,
                              message: "Veuillez remplir le champ",
                            },
                          ]}
                        >
                          <Input placeholder="Nom" />
                        </Form.Item>

                        <Form.Item
                          label="Prénom:"
                          name="prenom"
                          rules={[
                            {
                              required: true,
                              message: "Veuillez remplir le champ",
                            },
                          ]}
                        >
                          <Input placeholder="Prénom" />
                        </Form.Item>

                        <Form.Item
                          label="Sexe:"
                          name="sexe"
                          rules={[
                            {
                              required: true,
                              message: "Veuillez sélectioner un choix",
                            },
                          ]}
                        >
                          <Select>
                            <Select.Option value={1}>Masculin</Select.Option>
                            <Select.Option value={2}>Féminin</Select.Option>
                          </Select>
                        </Form.Item>

                        <Form.Item
                          label="Date de naissance:"
                          name="date_naissance"
                        >
                          <DatePicker
                            onChange={this.onDatePick.bind(this)}
                            placeholder="Sélectionner une date"
                            style={{ width: "100%" }}
                            rules={[
                              {
                                message: "Veuillez sélectionner une date",
                              },
                            ]}
                          />
                        </Form.Item>

                        <Form.Item
                          label="Téléphone:"
                          name="telephone"
                          rules={[
                            {
                              required: true,
                              message: "Veuillez remplir le champ",
                            },
                          ]}
                        >
                          <Input placeholder="Téléphone" />
                        </Form.Item>

                        <Divider />
                        <Form.Item
                          label={
                            <h4>
                              <b>Adresse</b>
                            </h4>
                          }
                        >
                          <Input.Group compact>
                            <Form.Item
                              name={["adresse", "adresse_numero"]}
                              label="Numéro de rue"
                              rules={[
                                {
                                  required: true,
                                  message: "Veuillez remplir le champ",
                                },
                              ]}
                            >
                              <InputNumber
                                style={{ width: "90%" }}
                                placeholder="Numéro de rue"
                              />
                            </Form.Item>
                            <Form.Item
                              name={["adresse", "adresse_complement_numero"]}
                              label="Complément numéro"
                            >
                              <Input
                                style={{ width: "90%" }}
                                placeholder="Complément numéro de rue"
                              />
                            </Form.Item>
                            <Form.Item
                              name={["adresse", "adresse_rue"]}
                              label="Rue"
                              rules={[
                                {
                                  required: true,
                                  message: "Veuillez remplir le champ",
                                },
                              ]}
                            >
                              <Input
                                style={{ width: "90%" }}
                                placeholder="Rue"
                              />
                            </Form.Item>

                            <Form.Item
                              name={["adresse", "adresse_complement"]}
                              label="Complément de rue"
                            >
                              <Input
                                style={{ width: "90%" }}
                                placeholder="Complément de rue"
                              />
                            </Form.Item>

                            <Form.Item
                              name={["adresse", "adresse_numero_porte"]}
                              label="Numéro de porte"
                            >
                              <InputNumber
                                style={{ width: "90%" }}
                                placeholder="Numéro de porte"
                                rules={[
                                  {
                                    message:
                                      "Veuillez remplir le champ (valeur supérieur à 0)",
                                    min: 1,
                                  },
                                ]}
                              />
                            </Form.Item>

                            <Form.Item
                              name={["adresse", "adresse_etage"]}
                              label="Étage"
                            >
                              <InputNumber
                                style={{ width: "90%" }}
                                placeholder="Étage"
                              />
                            </Form.Item>

                            <Form.Item
                              name={["adresse", "adresse_code_postal"]}
                              label="Code Postal"
                            >
                              <InputNumber
                                style={{ width: "90%" }}
                                placeholder="Code Postal"
                              />
                            </Form.Item>

                            <Form.Item
                              name={["adresse", "adresse_ville"]}
                              label="Ville"
                              rules={[
                                {
                                  required: true,
                                  message: "Veuillez remplir le champ",
                                },
                              ]}
                            >
                              <Input
                                style={{ width: "90%" }}
                                placeholder="Ville"
                              />
                            </Form.Item>

                            <Form.Item
                              name={["adresse", "adresse_pays"]}
                              label="Pays"
                              rules={[
                                {
                                  required: true,
                                  message: "Veuillez remplir le champ",
                                },
                              ]}
                            >
                              <Input
                                style={{ width: "90%" }}
                                placeholder="Pays"
                              />
                            </Form.Item>

                            <Form.Item
                              name={["adresse", "adresse_points_remarquables"]}
                              label="Informations complémentaires"
                              style={{ width: "100%" }}
                            >
                              <Input.TextArea
                                style={{ width: "100%" }}
                                placeholder="Informations complémentaires"
                              />
                            </Form.Item>
                          </Input.Group>
                        </Form.Item>

                        <Divider />
                        <Form.Item>
                          <Button type="primary" htmlType="submit" block>
                            Enregistrer les modifications
                          </Button>
                        </Form.Item>
                      </Card>
                    </Col>

                    <Col md={24} lg={12} xl={12}>
                      <Card
                        title={
                          <h2 style={{ margin: 0 }}>Personnes à prévenir</h2>
                        }
                      >
                        {[0, 1, 2, 3, 4, 5, 6]?.map((_, index) => {
                          return (
                          <>
                            <h3>Contact {index + 1}</h3>
                            <Row gutter={6}>
                              <Col md={12} sm={12} xs={24}>
                                <Form.Item
                                  label={`Nom :`}
                                  name={["contacts", `${index}`, "nom"]}
                                  rules={[
                                    {
                                      message: "Veuillez remplir le champ",
                                    },
                                  ]}
                                >
                                  <Input
                                    defaultValue={user?.contacts[index]?.nom}
                                    placeholder={`Nom`}
                                  />
                                </Form.Item>
                              </Col>

                              <Col md={12} sm={12} xs={24}>
                                <Form.Item
                                  label={`Prénom :`}
                                  name={["contacts", `${index}`, "prenom"]}
                                  rules={[
                                    {
                                      message: "Veuillez remplir le champ",
                                    },
                                  ]}
                                >
                                  <Input
                                    defaultValue={user?.contacts[index]?.prenom}
                                    placeholder={`Prénom`}
                                  />
                                </Form.Item>
                              </Col>

                              <Col md={10} sm={10} xs={24}>
                                <Form.Item
                                  label={`Téléphone :`}
                                  name={["contacts", `${index}`, "telephone"]}
                                  rules={[
                                    {
                                      message: "Veuillez remplir le champ",
                                    },
                                  ]}
                                >
                                  <Input
                                    defaultValue={
                                      user?.contacts[index]?.telephone
                                    }
                                    placeholder={`Téléphone`}
                                  />
                                </Form.Item>
                              </Col>
                              <Col md={8} sm={8} xs={24}>
                                <Form.Item
                                    label={`Seulement pour la balise :`}
                                    name={["contacts", `${index}`, "only_for_balise"]}
                                >
                                  <Select defaultValue={user?.contacts[index]?.only_for_balise ?? "" }>
                                    <Select.Option value={""}>Toutes</Select.Option>
                                    {user?.balises_associes.map((balise, index) => {
                                      return <Select.Option key={index} value={balise.id_balise}>{balise.ID_perso ? balise.ID_perso :  balise.id_balise}</Select.Option>
                                    })}
                                  </Select>
                                </Form.Item>
                              </Col>
                              <Col md={6} sm={6} xs={24}>
                                <Form.Item
                                    label={`Contact Pro:`}
                                    name={["contacts", `${index}`, "contact_pro"]}

                                >
                                  <Select defaultValue={user?.contacts[index]?.contact_pro ?? false} onChange={(e) => {
                                    if (user.contacts[index]) {
                                      user.contacts[index].contact_pro = e;
                                    }
                                    else {
                                      user.contacts[index] = {
                                        contact_pro: e,
                                      }
                                    }
                                  }}>
                                    <Select.Option value={false}>Non</Select.Option>
                                    <Select.Option value={true}>Oui</Select.Option>
                                  </Select>
                                </Form.Item>
                              </Col>
                            </Row>
                            <Divider />
                          </>
                        )})}
                        <Button type="primary" htmlType="submit" block>
                          Enregistrer les modifications
                        </Button>
                      </Card>
                    </Col>
                  </Row>
                </Form>
              )}
            </>
          ) : (
            <div
              style={{
                position: "absolute",
                left: "50%",
                top: "50%",
                transform: "translate(-50%, -50%)",
              }}
            >
              <Spin />
            </div>
          )}
        </CustomLayout>
      </div>
    );
  }
}

export default ProfilePage;

const UploadWrapper = styled.div`
  .ant-upload.ant-upload-select-picture-card {
    width: 150px;
    height: 150px;
  }
  text-align: center;
`;
