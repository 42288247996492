import axios from "axios";
import { API_URL, SOCIETE_URL } from "../config";
import { catchError } from "./response";

const getApiRequestService = (societeURL: boolean = false) => {
  return axios.create({
    baseURL: `${!societeURL ? API_URL : SOCIETE_URL}`,
    headers: {
      "Content-Type": "application/json",
      Authorization: ` ${
        localStorage.getItem("accessToken") !== null &&
        `Bearer ${localStorage.getItem("accessToken")}`
      }`,
    },
  });
};

export const getOrganisation = async (organisation_id: string) => {
  try {
    const res = await getApiRequestService().get(
      `/organisations/${organisation_id}`
    );

    return res.data;
  } catch (error) {
    console.log("GET_ORGANISATION", error);

    return catchError(error);
  }
};

export const searchUserOrganisation = async (
  organisation_id: string,
  search_value: string
) => {
  try {
    const res = await getApiRequestService().get(
      `/organisations/${organisation_id}/users/search?value=${search_value}`
    );

    return res.data;
  } catch (error) {
    console.log("SEARCH_USER_ORGANISATION", error);

    return catchError(error);
  }
};

export const getOrganisationBalises = async (organisation_id: string) => {
  try {
    const res = await getApiRequestService().get(
      `/organisations/${organisation_id}/balises`
    );

    return res.data;
  } catch (error) {
    console.log("GET_ORGANISATION_BALISES", error);

    return catchError(error);
  }
};

export const getOrganisationUsers = async (organisation_id: string) => {
  try {
    const res = await getApiRequestService().get(
      `/organisations/${organisation_id}/users`
    );

    return res.data;
  } catch (error) {
    console.log("GET_ORGANISATION_USERS", error);

    return catchError(error);
  }
};

export const deleteOrganisationUser = async (
  organisation_id: string,
  user_id: string
) => {
  try {
    const res = await getApiRequestService().delete(
      `/organisations/${organisation_id}/dissocier_user/${user_id}`
    );

    return res.data;
  } catch (error) {
    console.log("DELETE_ORGANISATION_USER", error);

    return catchError(error);
  }
};

export const addOrganisationUser = async (
  organisation_id: string,
  user_id: string
) => {
  try {
    const res = await getApiRequestService().post(
      `/organisations/${organisation_id}/add_user/${user_id}`
    );

    return res.data;
  } catch (error) {
    console.log("ADD_ORGANISATION_USER", error);

    return catchError(error);
  }
};

export const addOrganisationAdmin = async (
  organisation_id: string,
  user_id: string
) => {
  try {
    const res = await getApiRequestService().post(
      `/organisations/${organisation_id}/add_admin/${user_id}`
    );

    return res.data;
  } catch (error) {
    console.log("ADD_ORGANISATION_ADMIN", error);

    return catchError(error);
  }
};

export const searchBaliseOrganisation = async (
  organisation_id: string,
  search_value: string
) => {
  try {
    const res = await getApiRequestService().get(
      `/organisations/${organisation_id}/balises/search?value=${search_value}`
    );

    return res.data;
  } catch (error) {
    console.log("SEARCH_BALISE_ORGANISATION", error);

    return catchError(error);
  }
};

// Export QR Code PAR un user
export const exportByUser = async (
    users: string[],
  begin: string,
  end: string
) => {
  try {
    const res = await getApiRequestService(true).post(
      `/requests/called-by/from/${begin}/to/${end}`,
        {users: users}
    );

    return res.data;
  } catch (error) {
    console.log("EXPORT_BY_USER", error);

    return catchError(error);
  }
};


export const exportOnUser = async (
    users: string[],
    begin: string,
    end: string
) => {
  try {
    const res = await getApiRequestService(true).post(
        `/requests/called-on/from/${begin}/to/${end}`,
        {users: users}
    );

    return res.data;
  } catch (error) {
    console.log("EXPORT_ON_USER", error);

    return catchError(error);
  }
};


export const reformatCsv = (content: string) => {
  let csv : string = content.replaceAll(',', ';');
  csv = csv.replaceAll(' +0000 +0000', '');
  csv = csv.replaceAll("DateRequete", "Date du Scan");
  csv = csv.replaceAll("IdUserFrom", "Qui a scanné");
  csv = csv.replaceAll("IdUserTo", "Qui a été scanné");
  csv = csv.replaceAll("Requete", "Données Consultées");
  csv = csv.replaceAll("/api/societe/dossier-medical/:id", "Dossier Médical");
  csv = csv.replaceAll("/api/societe/users/:id", "Donnees personnelles");

  let finalCsv = '';

  const csvArray = csv.split('\n');

  for (const line of csvArray) {
    if (line.includes(';')) {
      let cols = line.split(';');
      let date = cols[4].split('.')[0];
      finalCsv +=  `${cols[1]};${cols[2]};${cols[3]};${date}\n`;
    }
  }

  return finalCsv;

}
