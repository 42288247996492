import React, { Component } from "react";
import { Divider, Table, Button, Row, Input, Modal, message } from "antd";
import CustomLayout from "../../components/CustomLayout";
import { renderAddress, Spacer } from "../../components/global";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { getProfile } from "../../services/users.services";
import Fuse from "fuse.js";
import {
  deleteOrganisationUser,
  getOrganisation,
} from "../../services/organisations.services";

const { Search } = Input;
const { confirm } = Modal;

class OrgasPage extends Component {
  state = {
    searchValue: "",
    showModal: false,
    showModalImport: false,
    organisations: [],
    constOrganisations: [],
  };

  componentDidMount() {
    this.handleGetProfile();
  }

  onSearch(value) {
    this.setState({ searchValue: value });
    const { constOrganisations } = this.state;

    if (value.length > 0) {
      const fuse = new Fuse(constOrganisations, {
        keys: ["id_organisation"],
        includeScore: true,
        findAllMatches: true,
        threshold: 0.1,
      });

      var results = fuse.search(value);

      console.log(results);

      var organisationsResults = [];

      if (results.length) {
        results.map((result) => {
          organisationsResults.push(result.item);
          this.setState({ organisations: organisationsResults });
          return 1;
        });
      } else {
        this.setState({ organisations: [] });
      }
    } else this.setState({ organisations: this.state.constOrganisations });
  }

  onDelete(organisation_id, user_id) {
    confirm({
      title: "Êtes vous sûr de vouloir supprimer cette organisation?",
      icon: <ExclamationCircleOutlined />,
      okText: "Oui",
      cancelText: "Annuler",
      onOk() {
        deleteOrganisationUser(organisation_id, user_id).then((_) => {
          message.success("Utilisateur supprimé de l'organisation");
          this.handleLoadInfos();
          window.location.reload();
        });
      },
      onCancel() {},
    });
  }

  handleGetProfile() {
    getProfile().then((res) => {
      console.log(res.user);
      this.setState(
        {
          user: res.user,
          organisations: res.user?.organisations_associees,
          constOrganisations: res.user?.organisations_associees,
        },
        () => {
          this.state.organisations.forEach((organisation, index) => {
            this.detailOrganisation(organisation.id_organisation, index);
          });
        }
      );
    });
  }

  detailOrganisation(id, index) {
    getOrganisation(id).then((res) => {
      renderAddress(res?.adresse);
      var organisations = this.state.organisations;

      organisations[index].adresse = renderAddress(res?.adresse);
      organisations[index].nom = res?.nom;

      this.setState({
        organisations,
      });
    });
  }

  render() {
    const columns = [
      {
        title: "Organisation ID",
        dataIndex: "id",
        key: "id",
        width: "15%",
      },
      {
        title: "Administrateur",
        dataIndex: "isAdmin",
        key: "isAdmin",
        render: (value) => <b>{value ? "OUI" : "NON"}</b>,
      },

      {
        title: "Nom",
        dataIndex: "nom",
        key: "nom",
        width: "25%",
      },

      {
        title: "Adresse",
        dataIndex: "adresse",
        key: "adresse",
        width: "35%",
      },

      {
        title: "",
        dataIndex: "actions",
        key: "actions",
        responsive: ["sm"],
        width: "50%",
        render: (_, record) => (
          <>
            <Row gutter={[24, 10]} justify="end" style={{ paddingRight: 50 }}>
              {record.isAdmin && (
                <>
                  <Button
                    style={{ marginRight: 10 }}
                    onClick={() =>
                      this.props.history.push(
                        `/organisations/${record.id}?isAdmin=${record.isAdmin}`
                      )
                    }
                  >
                    Détails
                  </Button>
                  <Button
                    danger
                    onClick={() => this.onDelete(record.id, this.state.user.id)}
                  >
                    Supprimer
                  </Button>
                </>
              )}
            </Row>
          </>
        ),
      },
    ].filter((v) => !v.hidden);

    const { organisations } = this.state;

    return (
      <div>
        <CustomLayout {...this.props} selectedKey="organisations">
          <Row justify="space-between" align="middle" gutter={[6, 12]}>
            <h1 style={{ margin: 0 }}>Mes organisations</h1>
          </Row>
          <Divider />
          <Row justify="space-between" align="middle">
            <Search
              style={{ width: "auto" }}
              placeholder="Rechercher"
              onInput={(e) => this.onSearch(e.target.value)}
              enterButton
            />
            <h4 style={{ margin: 0, fontWeight: 800 }}>
              {organisations?.length} organisation
              {organisations?.length > 1 && "s"}
            </h4>
          </Row>
          <Spacer />
          <Table
            columns={columns}
            dataSource={organisations?.map((organisation) => ({
              key: organisation?.id,
              id: organisation?.id_organisation,
              isAdmin: organisation?.administrateur_organisation,
              adresse: organisation?.adresse,
              nom: organisation?.nom,
            }))}
          />
        </CustomLayout>
      </div>
    );
  }
}

export default OrgasPage;
