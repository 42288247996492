import { message } from "antd";
import axios from "axios";
import { LOGIN_URL, API_URL } from "../config";
import { catchError } from "./response";

const getApiRequestService = (baseURL?: string) => {
  return axios.create({
    baseURL: `${baseURL ?? LOGIN_URL}`,
    headers: {
      "Content-Type": "application/json",
      Authorization: ` ${
        localStorage.getItem("accessToken") !== null &&
        `Bearer ${localStorage.getItem("accessToken")}`
      }`,
    },
  });
};

export const loginUser = async (body: Record<string, string>) => {
  try {
    const res = await getApiRequestService().post("/backend", body);

    return res.data;
  } catch (error) {
    console.log("LOGIN_USER", error);

    return catchError(error);
  }
};

export const firstLoginUser = async (body: Record<string, string>) => {
  try {
    const res = await getApiRequestService(API_URL).put(
      "/users/premiereConnexion",
      body
    );

    message.success("Vos identifiants on été mis à jour avec succès");
    message.info("Veuillez vous reconnecter avec vos nouveaux identifiants");

    return res.data;
  } catch (error: any) {
    console.log("FIRST_LOGIN_USER", error);
    message.error("Une erreur est survenue: " + error.response.data.error);
    return catchError(error);
  }
};

export const resetPassword = async (email: string) => {
  try {
    const res = await getApiRequestService(
      "https://api.societe.helpp-life.fr"
    ).get(`/sendResetLink/${email}`);

    return res.data;
  } catch (error) {
    console.log("RESET_PASSWORD", error);

    return catchError(error);
  }
};
