import React, { Component } from "react";
import {
  Divider,
  Tag,
  Table,
  Button,
  Row,
  Col,
  Input,
  Tooltip,
  Modal,
  Select,
  Upload,
  message,
  Checkbox,
} from "antd";
import CustomLayout from "../../components/CustomLayout";
import { Spacer, Spinner } from "../../components/global";
import {
  getAllBalises,
  getBaliseIdPerso,
  setBaliseAlert,
  unsetBaliseAlert,
  unsetBaliseGeoAlert,
} from "../../services/balises.services";
import { ExclamationCircleOutlined, UploadOutlined } from "@ant-design/icons";
import Fuse from "fuse.js";
import {getProfile} from "../../services/users.services";

const { Search } = Input;
const { confirm } = Modal;
const { Option } = Select;

class HomePage extends Component {
  state = {
    searchValue: "",
    balises: [],
    dataSource: [],
    constBalises: [],
    showModal: false,
    showModalImport: false,
    check_agressor: false,
    select_service: 18,
    context_pro: false,
    loaded: false,
    user_id: null,
  };

  intervalID = 0;

  componentDidMount() {
    console.log("accessToken", localStorage.getItem("accessToken"));
    getAllBalises().then((res) => {
      console.log(res);
      this.setState({ balises: res?.balises, constBalises: res?.balises }, () =>
        this.fetchBalises(true)
      );
    });

    getProfile().then(({ user }) => {
      this.setState({
        user_id: user.id
      });
    });

    setTimeout(() => {
      getAllBalises().then((res) => {
        this.setState(
          { balises: res?.balises, constBalises: res?.balises },
          () => this.fetchBalises(false)
        );
      });
    }, 1500);
  }

  onSearch(value) {
    this.setState({ searchValue: value });
    const { constBalises } = this.state;

    if (value.length > 0) {
      const fuse = new Fuse(constBalises, {
        keys: ["id_helpp_life"],
        includeScore: true,
        findAllMatches: true,
        threshold: 0.2,
      });

      var results = fuse.search(this.state.searchValue);
      console.log(results);

      var balisesResults = [];
      if (results.length) {
        results.forEach((result) => {
          balisesResults.push(result.item);
        });
        console.log(balisesResults);
        this.setState({ balises: balisesResults });
      } else {
        this.setState({ balises: [] });
      }
    } else {
      getAllBalises().then((res) => {
        console.log(res);
        this.setState(
          { balises: res?.balises, constBalises: res?.balises },
          () => this.fetchBalises(true)
        );
      });
      setTimeout(() => {
        this.fetchBalises(false);
      }, 1000);
    }

    this.fetchBalises(false);
  }

  changeBalise(balise_id, balise) {
    const onOk = () => {
      if (balise?.en_alerte) {
        if (balise?.appkey?.length)
          unsetBaliseAlert(balise_id).then((res) => {
            if (res?.status === 500) message.error(res?.error);
            else window.location.reload();
          });
        else
          unsetBaliseGeoAlert(balise_id).then((res) => {
            if (res?.status === 500) message.error(res?.error);
            else window.location.reload();
          });
      } else {
        const data = {
          id_user: this.state.user_id || balise?.id_user_for_who,
          proximity_of_agressor: this.state.check_agressor,
          selected_service: `${this.state.select_service}`,
          context_pro: this.state.context_pro,
        };

        setBaliseAlert(balise_id, data).then((res) => {
          if (res?.status === 500) message.error(res?.error);
          else window.location.reload();
        });
      }
    };

    const onCancel = () => {
      console.log("canceled");
      this.setState({ check_agressor: false, select_service: 18 });
    };

    if (balise?.en_alerte) {
      confirm({
        icon: <ExclamationCircleOutlined />,
        content: (
          <p>
            Êtes vous sûr de vouloir{" "}
            {balise?.en_alerte ? "désactiver" : "activer"} la balise?
          </p>
        ),
        onOk,
        onCancel,
      });
    } else {
      confirm({
        content: (
          <>
            <h3 style={{ fontWeight: 600 }}>
              Déclenchement de la balise {balise_id}
            </h3>
            <Spacer />
            <h4>Contexte</h4>
            <Select
                placeholder="Contexte"
                style={{ width: "100%", marginBottom: 12 }}
                defaultValue={false}
                onChange={(v) => this.setState({ context_pro: v })}
            >
              <Option value={false}>Vie Privée</Option>
              <Option value={true}>Professionel</Option>
            </Select>
            <h4>Type de secours</h4>
            <Select
              placeholder="Choix du type de secours"
              style={{ width: "100%" }}
              defaultValue={18}
              onChange={(v) => this.setState({ select_service: v })}
            >
              <Option value={18}>Pompier</Option>
              <Option value={15}>SAMU</Option>
              <Option value={17}>Force de l'ordre</Option>
            </Select>
            <Spacer height="10px" />
            <Checkbox
              onChange={(e) =>
                this.setState({ check_agressor: e.target.checked })
              }
            >
              Agresseur à proximité
            </Checkbox>
          </>
        ),
        onOk,
        onCancel() {
          console.log("canceled");
        },
      });
    }
  }

  handleOk() {
    this.setState({ showModal: false });
  }

  handleCancel() {
    this.setState({ showModal: false });
  }

  fetchBalises = async (reload_id) => {
    var dataSource = [];

    this.state.balises?.forEach((balise, index) => {
      const {
        adresse_numero,
        adresse_rue,
        adresse_code_postal,
        adresse_ville,
        adresse_pays,
      } = balise?.adresse;

      const filtered_address =
        adresse_numero +
        " " +
        adresse_rue +
        " " +
        adresse_code_postal +
        " " +
        adresse_ville +
        " " +
        adresse_pays;

      var data = {
        key: balise?.id,
        balise: balise,
        id: balise?.id,
        id_helpp_life: balise?.id_helpp_life,
        id_perso: this.state.dataSource[index]?.id_perso,
        address: balise?.appkey.length > 0 ? filtered_address : adresse_rue,
        etat: balise?.etat,
        status: balise?.balise_status,
      };

      dataSource.push(data);
    });

    if (reload_id)
      dataSource?.forEach((data) =>
        getBaliseIdPerso(data?.id).then((res) => (data.id_perso = res.id_perso))
      );

    this.setState({ dataSource, loaded: !reload_id });
  };

  render() {
    const { balises, showModal, showModalImport, loaded } = this.state;

    const columns = [
      {
        title: "ID Helpp-Life",
        dataIndex: "id_helpp_life",
        key: "id_helpp_life",
      },

      {
        title: "",
        dataIndex: "id",
        key: "id",
        hidden: true,
      },

      {
        title: "",
        dataIndex: "balise",
        key: "balise",
        hidden: true,
      },
      {
        title: "ID Perso",
        dataIndex: "id_perso",
        key: "id_perso",
      },
      {
        title: "Adresse",
        dataIndex: "address",
        key: "address",
      },
      {
        title: "État",
        dataIndex: "etate",
        key: "etate",
        hidden: true,
        render: (status, record) => {
          if (status === "EN_SERVICE") {
            return (
              <Tooltip title="AVAILABLE">
                <Tag color="#52c41a" key={`AVAILABLE-${record?.id}`}>
                  &nbsp;
                </Tag>
              </Tooltip>
            );
          } else {
            return (
              <Tooltip title="NON_SERVICE">
                <Tag color="#8c8c8c" key={`NON_SERVICE-${record?.id}`}>
                  &nbsp;
                </Tag>
              </Tooltip>
            );
          }
        },
      },
      {
        title: "Status",
        dataIndex: "status",
        key: "status",
        render: (status, record) => {
          var color = "";
          switch (status?.toUpperCase()) {
            case "LOST":
              color = "#c23616";
              break;

            case "AVAILABLE":
              color = "#44bd32";
              break;

            default:
              color = "#e1b12c";
              break;
          }

          return (
            <Tooltip title={status ?? "OFF"}>
              <Tag color={color} key={`${status + record?.id}`}>
                &nbsp;
              </Tag>
            </Tooltip>
          );
        },
      },
      {
        title: "",
        dataIndex: "actions",
        key: "actions",
        responsive: ["sm"],
        width: "20%",

        render: (_, record) => (
          <>
            <Row gutter={[24, 10]} justify="start">
              <Button
                type="primary"
                style={{ marginRight: 10 }}
                onClick={() =>
                  this.props.history.push(`/balises/detail/${record.id}`)
                }
              >
                Détails
              </Button>
              <Button
                onClick={() => this.changeBalise(record.id, record.balise)}
                danger={record?.balise?.en_alerte}
              >
                {record?.balise?.en_alerte ? "Arrêter" : "Déclencher"}
              </Button>
            </Row>
          </>
        ),
      },
    ].filter((item) => !item.hidden);

    const props = {
      name: "file",
      action: "https://www.mocky.io/v2/5cc8019d300000980a055e76",
      headers: {
        authorization: "authorization-text",
      },
      onChange(info) {
        if (info.file.status !== "uploading") {
          console.log(info.file, info.fileList);
        }
        if (info.file.status === "done") {
          message.success(`${info.file.name} file uploaded successfully`);
        } else if (info.file.status === "error") {
          message.error(`${info.file.name} file upload failed.`);
        }
      },
    };

    return (
      <div>
        <CustomLayout {...this.props} selectedKey="balises">
          <Row justify="space-between" align="middle" gutter={[6, 12]}>
            <h1 style={{ margin: 0 }}>Mes balises</h1>
          </Row>
          <Divider />
          <Row justify="space-between" align="middle">
            <Search
              style={{ width: "auto" }}
              placeholder="Rechercher"
              // onSearch={(value) => this.onSearch(value)}
              onInput={(e) => this.onSearch(e.target.value)}
              enterButton
              disabled={!this.state.loaded}
            />
            <h4 style={{ margin: 0, fontWeight: 800 }}>
              {balises?.length} balises
            </h4>
          </Row>
          <Spacer />

          {loaded ? (
            <Table columns={columns} dataSource={this.state.dataSource} />
          ) : (
            <Spinner />
          )}
        </CustomLayout>
        <Modal
          title="Créer une balise"
          visible={showModal}
          onOk={this.handleOk.bind(this)}
          onCancel={this.handleCancel.bind(this)}
          okText="Créer"
          cancelText="Annuler"
        >
          <form>
            <Row gutter={[16, 24]}>
              <Col sm={12} xs={24}>
                <span>Numéro de série*</span>
                <Input placeholder="Numéro de série" />
              </Col>
              <Col sm={12} xs={24}>
                <span>DevEUI*</span>
                <Input placeholder="DevEUI" />
              </Col>
              <Col sm={12} xs={24}>
                <span>AppEUI*</span>
                <Input placeholder="AppEUI" />
              </Col>
              <Col sm={12} xs={24}>
                <span>AppKey*</span>
                <Input placeholder="AppKey" />
              </Col>
              <Col sm={12} xs={24}>
                <span>Classe Lora*</span>
                <Input placeholder="Classe Lora" />
              </Col>
              <Col sm={12} xs={24}>
                <span>Champ libre</span>
                <Input placeholder="Champ libre" value="Helpp-life_" />
              </Col>
              <Col sm={24} xs={24}>
                <span>Statut de la balise</span>
                <Select
                  style={{ width: "100%" }}
                  placeholder="Choisir le statut de la balise"
                >
                  <Option value="louee">Louée</Option>
                  <Option value="vendue">Vendue</Option>
                  <Option value="stock">En stock</Option>
                </Select>
              </Col>
            </Row>
          </form>
        </Modal>

        <Modal
          title="Importer une balise"
          visible={showModalImport}
          onOk={() => this.setState({ showModalImport: false })}
          onCancel={() => this.setState({ showModalImport: false })}
          okText="Importer"
          cancelText="Annuler"
        >
          <center>
            <p>
              Si vous ne savez pas comment faire votre CSV pour l'import, merci
              de vous fier au fichier <u>exemple</u>.
            </p>
            <Spacer height="25px" />

            <Upload {...props}>
              <Button block size="large" icon={<UploadOutlined />}>
                Importer un fichier
              </Button>
            </Upload>
            <Spacer height="15px" />
            <Checkbox>Je confirme que mon fichier CSV est valide.</Checkbox>
          </center>
        </Modal>
      </div>
    );
  }
}

export default HomePage;
