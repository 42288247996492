import React, { Component } from "react";
import styled from "styled-components";
import { Divider, Row } from "antd";
import { loginUser } from "../../services/auth.services";
import Logo from "../../assets/images/logo.png";

class QRPage extends Component {
  authLogin() {
    loginUser().then((res) => {
      // console.log(res);
      localStorage.setItem("accessToken", res.token);
    });
    this.props.history.push("/balises");
  }

  render() {
    const user_id = this.props.match.params.id;
    return (
      <>
        <LoginWrapper>
          <CardWrapper>
            <center>
              <img src={Logo} alt="" style={{ height: 40, margin: 0 }} />
              <h2 style={{ margin: 10, fontWeight: 600 }}>
                Utilisateur {user_id}
              </h2>
            </center>
            <Divider />
            <div style={{ padding: "0 10%" }}>
              <Row gutter={[12, 24]} justify="center">
                <img
                  src="https://upload.wikimedia.org/wikipedia/commons/7/78/Qrcode_wikipedia_fr_v2clean.png"
                  alt=""
                  style={{ height: 250 }}
                />
                <Divider />
                <h3 style={{ margin: 0, fontWeight: 600 }}>
                  Informations complémentaires
                </h3>
                <p style={{ marginTop: 0, color: "black" }}>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Aenean a porttitor ligula. Fusce auctor mi ut urna viverra
                  ornare. Nullam commodo aliquet dolor, at cursus quam semper a.
                  Suspendisse a mauris varius, ornare purus id, congue nisi. Ut
                  ac hendrerit lacus. Curabitur eget mi quis nunc imperdiet
                  porttitor. Curabitur ornare ipsum finibus mauris pellentesque
                  egestas. Donec eget leo dui.
                </p>
              </Row>
            </div>
          </CardWrapper>
        </LoginWrapper>
      </>
    );
  }
}

export default QRPage;

const LoginWrapper = styled.div`
  background: #000;
  position: relative;
  padding: 20px;
  min-height: ${window.innerHeight}px;
  // max-height: 100%;
  color: white;

  h1 {
      text-align: center;
      font-weight 800;
      margin: 0
  }
`;

const CardWrapper = styled.div`
  background: white;
  padding: 20px;
`;
