import React, { Component } from "react";
import styled from "styled-components";
import BrandLogo from "../../assets/images/logo.png";

class SplashPage extends Component {
  componentDidMount() {
    setTimeout(() => {
      this.props.history.push("/login");
    }, 3000);
  }

  render() {
    return (
      <SplashWrapper style={{ height: `${window.innerHeight}px` }}>
        <img src={BrandLogo} alt=" " onDragStart={(e) => e.preventDefault()} />
      </SplashWrapper>
    );
  }
}

export default SplashPage;

const SplashWrapper = styled.div`
  position: relative;
  background: #000;
  img {
    height: 100px;
    position: absolute;
    top: 45%;
    left: 45%;
    user-select: none;
    animation: scalingLogo 3.2s ease-in-out infinite alternate;
    -webkit-animation: scalingLogo 3.2s ease-in-out infinite alternate;
  }

  @media (max-width: 500px) {
    img {
      top: 50%;
      left: 38%;
    }
  }

  @keyframes scalingLogo {
    0% {
      transform: scale(1);
      -webkit-transform: scale(1);
    }
    100% {
      transform: scale(1.5);
      -webkit-transform: scale(1.5);
    }
  }
`;
