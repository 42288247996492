import React, { Component } from "react";
import { Divider, Button, Row, Card, Col } from "antd";
import CustomLayout from "../../components/CustomLayout";
import { Spacer } from "../../components/global";
import {
  getMedicalFolderPhoto,
  getProfile,
} from "../../services/users.services";
import ReactToPrint from "react-to-print";

class TagPage extends Component {
  state = {};

  componentDidMount() {
    // Get current profile
    getProfile().then((res) => {
      console.log("profile", res);
      this.setState({ user: res?.user });

      // Get medical folder photos
      getMedicalFolderPhoto(0).then((qrcode_img) => {
        this.setState({ qrcode_img });
      });
    });
  }

  downloadBlob(blob, name = "qrcode.png") {
    // Convert your blob into a Blob URL (a special url that points to an object in the browser's memory)
    const blobUrl = this.state.qrcode_img;

    // Create a link element
    const link = document.createElement("a");

    // Set link's href to point to the Blob URL
    link.href = blobUrl;
    link.download = name;

    // Append link to the body
    document.body.appendChild(link);

    // Dispatch click event on the link
    // This is necessary as link.click() does not work on the latest firefox
    link.dispatchEvent(
      new MouseEvent("click", {
        bubbles: true,
        cancelable: true,
        view: window,
      })
    );

    // Remove link from body
    document.body.removeChild(link);
  }

  render() {
    const { user, qrcode_img } = this.state;
    return (
      <div>
        <CustomLayout {...this.props} selectedKey="tags">
          <Row justify="space-between" align="middle" gutter={[6, 12]}>
            <h1 style={{ margin: 0 }}>Mon Tag</h1>
          </Row>
          <Divider />
          <Row justify="space-between" align="middle">
            <Col xs={24} md={8}>
              <Card
                title={<h2 style={{ margin: 0 }}>{user?.id_helpp_life}</h2>}
              >
                <img
                  src={qrcode_img}
                  alt=""
                  width="100%"
                  ref={(el) => (this.componentRef = el)}
                />
                <Divider />
                <Row justify="space-between">
                  <ReactToPrint
                    trigger={() => {
                      return <Button>Imprimer</Button>;
                    }}
                    content={() => this.componentRef}
                  />
                  <Button onClick={this.downloadBlob.bind(this)}>
                    Télécharger
                  </Button>
                </Row>
              </Card>
            </Col>
          </Row>
          <Spacer />
        </CustomLayout>
      </div>
    );
  }
}

export default TagPage;
