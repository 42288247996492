import React, { Component } from "react";
import {
  Divider,
  Button,
  Row,
  Card,
  Col,
  Input,
  Form,
  Modal,
  Spin,
  message,
  Select,
  Checkbox,
} from "antd";
import CustomLayout from "../../components/CustomLayout";
import { Spacer, renderAddress } from "../../components/global";
import { LeftOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import {
  editBalise,
  getBalise,
  getBaliseIdPerso,
  setBaliseAlert,
  unsetBaliseAlert, unsetBaliseGeoAlert,
  updateBaliseIdPerso,
} from "../../services/balises.services";
import Geocode from "react-geocode";
//import GoogleMapReact from "google-map-react";
import { GOOGLE_API_KEY } from "../../config";
import { getProfile } from "../../services/users.services";

const { TextArea } = Input;
const { confirm } = Modal;
const { Option } = Select;

class DetailBalise extends Component {
  constructor(props) {
    super(props);

    this.state = {
      searchValue: "",
      balise_id: "",
      loaded: false,
      check_agressor: false,
      select_service: 18,
      markerChanged: false,
      context_pro: false,
      Marker: {
        name: "Current position",
        position: {
          lat: 0,
          lng: 0,
        },
      },
    };

    this.renderMarker = this.renderMarker.bind(this);
  }

  componentDidMount() {
    this.fetchBalise();

    // set Google Maps Geocoding API for purposes of quota management. Its optional but recommended.
    Geocode.setApiKey(GOOGLE_API_KEY);
    Geocode.setLanguage("en");
  }

  getCoord(address) {
    Geocode.fromAddress(address).then(
      (response) => {
        var location = response.results[0].geometry.location;
        var Marker = this.state.Marker;
        Marker.position = location;
        this.setState({ Marker }, () => console.log(this.state.Marker));
      },
      (error) => {
        console.error(error);
      }
    );
  }

  fetchBalise() {
    const balise_id = this.props.match.params.id;
    getBalise(balise_id).then((res) => {
      console.log(res?.balise);
      this.setState(
        {
          balise: res?.balise,
          loaded: true,
        },
        () => {
          var Marker = this.state.Marker;
          Marker.position = {
            lat: this.state.balise?.lat ?? 43,
            lng: this.state.balise?.lon ?? 5,
          };

          this.setState({ Marker }, () => {
            if (
              // true
              this.state.Marker.position.lat === 0 &&
              this.state.Marker.position.lng === 0
            ) {
              const adresse = renderAddress(this.state.balise.adresse);
              this.getCoord(adresse);
            }
          });
        }
      );
    });
    getBaliseIdPerso(balise_id).then((res) =>
      this.setState({ id_perso: res?.id_perso })
    );
  }

  changeBalise = async () => {
    const { balise } = this.state;
    const balise_id = this.props.match.params.id;
    let user_id = null;

    await getProfile().then(({ user }) => {
      user_id = user.id;
    });

    const onOk = () => {
      if (balise?.en_alerte) {
        if (balise?.appkey?.length)
          unsetBaliseAlert(balise_id).then((res) => {
            if (res?.status === 500) message.error(res?.error);
            else message.success("État de la balise modifiée avec succès");
            this.fetchBalise();
          });
        else
          unsetBaliseGeoAlert(balise_id).then((res) => {
            if (res?.status === 500) message.error(res?.error);
            else message.success("État de la balise modifiée avec succès");
            this.props.history.goBack();
          });
      } else {
        const data = {
          // id_user: balise?.id_user_for_who,
          id_user: user_id || balise?.id_user_for_who,
          proximity_of_agressor: this.state.check_agressor,
          selected_service: `${this.state.select_service}`,
          context_pro: this.state.context_pro,
        };

        setBaliseAlert(balise_id, data).then((res) => {
          if (res?.status === 500) message.error(res?.error);
          else message.success("État de la balise modifiée avec succès");
          this.fetchBalise();
        });
      }
    };

    const onCancel = () => {
      console.log("canceled");
      this.setState({ check_agressor: false, select_service: 18 });
    };

    if (balise?.en_alerte) {
      confirm({
        icon: <ExclamationCircleOutlined />,
        content: (
          <p>
            Êtes vous sûr de vouloir{" "}
            {balise?.en_alerte ? "désactiver" : "activer"} la balise?
          </p>
        ),
        onOk,
        onCancel,
      });
    } else {
      confirm({
        content: (
          <>
            <h3 style={{ fontWeight: 600 }}>
              Déclenchement de la balise {balise_id}
            </h3>
            <Spacer />
            <h4>Contexte</h4>
            <Select
                placeholder="Contexte"
                style={{ width: "100%", marginBottom: 12 }}
                defaultValue={false}
                onChange={(v) => this.setState({ context_pro: v })}
            >
              <Option value={false}>Vie Privée</Option>
              <Option value={true}>Professionel</Option>
            </Select>
            <h4>Type de secours</h4>
            <Select
              placeholder="Choix du type de secours"
              style={{ width: "100%" }}
              defaultValue={18}
              onChange={(v) => this.setState({ select_service: v })}
            >
              <Option value={18}>Pompier</Option>
              <Option value={15}>SAMU</Option>
              <Option value={17}>Force de l'ordre</Option>
            </Select>
            <Spacer height="10px" />
            <Checkbox
              onChange={(e) =>
                this.setState({ check_agressor: e.target.checked })
              }
            >
              Agresseur à proximité
            </Checkbox>
          </>
        ),
        onOk,
        onCancel() {
          console.log("canceled");
        },
      });
    }
  };

  onFinish(values) {
    console.log("Success:", values);
    const { balise } = this.state;

    const data = {
      id: balise?.id,
      adresse: {
        adresse_code_postal: parseInt(values.adresse_code_postal),
        adresse_complement: values.adresse_complement,
        adresse_complement_numero: values.adresse_complement_numero,
        adresse_etage: parseInt(values.adresse_etage),
        adresse_numero: parseInt(values.adresse_numero),
        adresse_numero_porte: parseInt(values.adresse_numero_porte),
        adresse_pays: values.adresse_pays,
        adresse_points_remarquables: values.adresse_points_remarquables,
        adresse_rue: values.adresse_rue,
        adresse_ville: values.adresse_ville,
      },
      info_techniques: values.info_techniques,
      info_personnelles: values.info_personnelles,
    };

    editBalise(balise?.id, data).then((res) => {
      console.log("res_edit", res);
    });

    console.log("Final", data);
  }

  onFinishFailed(errorInfo) {
    console.log("Failed:", errorInfo);
  }

  renderMarker(map, maps) {
    const { Marker } = this.state;

    let marker = new maps.Marker({
      position: Marker.position,
      zoom: Marker.zoom,
      draggable: true,
      dragend: (e) => console.log(e),
      map,
      title: "Helpp-Life",
    });

    maps.event.addListener(marker, "dragend", (e) => {
      var newMarker = this.state.Marker;
      newMarker.position = {
        lat: e.latLng.lat(),
        lng: e.latLng.lng(),
      };
      this.setState({
        Marker: newMarker,
        markerChanged: true,
      });
    });

    return marker;
  }

  handleUpdateCoord() {
    const { Marker, balise } = this.state;

    Geocode.fromLatLng(Marker.position.lat, Marker.position.lng).then((res) => {
      const address = res.results[0];
      console.log(address);

      const adresse = {
        adresse_numero: parseInt(address?.address_components[0]?.long_name),
        adresse_rue: address?.address_components[1]?.long_name,
        adresse_code_postal: parseInt(
          address?.address_components[6]?.long_name
        ),
        adresse_ville: address?.address_components[2]?.long_name,
        adresse_pays: address?.address_components[5]?.long_name,
      };

      const data = {
        id: balise?.id,
        adresse: adresse,
        lat: Marker.position.lat,
        lon: Marker.position.lon,
        info_techniques: balise.info_techniques,
        info_personnelles: balise.info_personnelles,
      };

      editBalise(balise?.id, data).then((res) => {
        console.log("res_edit", res);
        message.success("Balise mise à jour", 1, () => {
          window.location.reload();
        });
      });
    });
  }

  render() {
    const { balise, loaded, id_perso } = this.state;
    const balise_id = this.props.match.params.id;

    return (
      <div>
        <CustomLayout {...this.props} selectedKey="warnings">
          <Row justify="start" align="middle" gutter={[6, 12]}>
            <Button
              shape="circle"
              icon={<LeftOutlined />}
              onClick={() => this.props.history.goBack()}
            />
            <h1 style={{ margin: 0, marginLeft: 20 }}>
              Balise {id_perso?.length ? id_perso : balise?.id_helpp_life}
            </h1>
          </Row>

          <Divider orientation="right">
            <Button
              danger={balise?.en_alerte}
              onClick={this.changeBalise.bind(this)}
            >
              {balise?.en_alerte ? "Arrêter" : "Déclencher"} la balise
            </Button>
          </Divider>
          <Spacer />
          <Row gutter={[24, 12]}>
            <Col md={24} lg={24} xl={24}>
              <Card title={<h2 style={{ margin: 0 }}>Détails</h2>}>
                <Row justify="space-around">
                  <Col>
                    <h4>En alerte</h4>
                    <b>{balise?.en_alerte ? "Oui" : "Non"}</b>
                  </Col>
                  <Col>
                    <h4>Niveau de batterie</h4>
                    <b>{balise?.niveau_batterie}</b>
                  </Col>
                  <Col>
                    <h4>Sur secteur</h4>
                    <b>{balise?.sur_secteur ? "Oui" : "Non"}</b>
                  </Col>
                </Row>
                <Spacer />
                <Divider />
                {/**
                 {this.state.Marker?.position?.lat !== 0 && (
                  <>
                    <center>
                      <div
                        style={{
                          height: "250px",
                          width: "100%",
                          maxWidth: "600px",
                        }}
                      >

                        <GoogleMapReact
                          bootstrapURLKeys={{ key: GOOGLE_API_KEY }}
                          defaultCenter={this.state.Marker.position}
                          defaultZoom={13}
                          yesIWantToUseGoogleMapApiInternals={true}
                          onGoogleApiLoaded={({ map, maps }) =>
                            this.renderMarker(map, maps)
                          }
                        ></GoogleMapReact>

                      </div>
                      <Button
                        style={{ maxWidth: "600px", marginTop: 15 }}
                        block
                        type="primary"
                        disabled={!this.state.markerChanged}
                        onClick={this.handleUpdateCoord.bind(this)}
                      >
                        Enregsitrer la nouvelle position
                      </Button>
                    </center>
                  </>
                )}
                 **/}
                <Spacer />
                <Divider orientation="left">
                  <h3 style={{ margin: 0 }}>Adresse</h3>
                </Divider>
                {loaded ? (
                  <>
                    <Form
                      name="basic"
                      onFinish={this.onFinish.bind(this)}
                      onFinishFailed={this.onFinishFailed.bind(this)}
                      layout="vertical"
                      initialValues={{
                        adresse_numero: balise?.adresse?.adresse_numero,
                        adresse_complement_numero:
                          balise?.adresse?.adresse_complement_numero,
                        adresse_rue: balise?.adresse?.adresse_rue,
                        adresse_complement: balise?.adresse?.adresse_complement,
                        adresse_numero_porte:
                          balise?.adresse?.adresse_numero_porte,
                        adresse_etage: balise?.adresse?.adresse_etage,
                        adresse_code_postal:
                          balise?.adresse?.adresse_code_postal,
                        adresse_pays: balise?.adresse?.adresse_pays,
                        adresse_ville: balise?.adresse?.adresse_ville,
                        adresse_points_remarquables:
                          balise?.adresse?.adresse_points_remarquables,
                        //
                        info_techniques: balise?.info_techniques,
                        info_personnelles: balise?.info_personnelles,
                      }}
                    >
                      <Row gutter={16}>
                        <Col sm={12} xs={24}>
                          <Form.Item label="Numéro" name="adresse_numero">
                            <Input
                              placeholder="Numéro"
                              key={`${balise?.adresse?.adresse_numero}`}
                              defaultValue={balise?.adresse?.adresse_numero}
                            />
                          </Form.Item>
                        </Col>
                        <Col sm={12} xs={24}>
                          <Form.Item
                            label="Complément de numéro"
                            name="adresse_complement_numero"
                          >
                            <Input
                              placeholder="Complément de numéro"
                              key={`${balise?.adresse?.adresse_complement_numero}`}
                              defaultValue={
                                balise?.adresse?.adresse_complement_numero
                              }
                            />
                          </Form.Item>
                        </Col>

                        <Col sm={12} xs={24}>
                          <Form.Item label="Rue" name="adresse_rue">
                            <Input
                              placeholder="rue"
                              key={`${balise?.adresse?.adresse_rue}`}
                              defaultValue={balise?.adresse?.adresse_rue}
                            />
                          </Form.Item>
                        </Col>

                        <Col sm={12} xs={24}>
                          <Form.Item
                            label="Complément de rue"
                            name="adresse_complement"
                          >
                            <Input
                              placeholder="Complément de rue"
                              key={`${balise?.adresse?.adresse_complement}`}
                              defaultValue={balise?.adresse?.adresse_complement}
                            />
                          </Form.Item>
                        </Col>

                        <Col sm={12} xs={24}>
                          <Form.Item
                            label="Numéro de porte"
                            name="adresse_numero_porte"
                          >
                            <Input
                              placeholder="Numéro de porte"
                              key={`${balise?.adresse?.adresse_numero_porte}`}
                              defaultValue={
                                balise?.adresse?.adresse_numero_porte
                              }
                            />
                          </Form.Item>
                        </Col>

                        <Col sm={12} xs={24}>
                          <Form.Item label="Étage" name="adresse_etage">
                            <Input
                              placeholder="Étage"
                              key={`${balise?.adresse?.adresse_etage}`}
                              defaultValue={balise?.adresse?.adresse_etage}
                            />
                          </Form.Item>
                        </Col>

                        <Col sm={12} xs={24}>
                          <Form.Item
                            label="Code Postal"
                            name="adresse_code_postal"
                          >
                            <Input
                              placeholder="Code Postal"
                              key={`${balise?.adresse?.adresse_code_postal}`}
                              defaultValue={
                                balise?.adresse?.adresse_code_postal
                              }
                            />
                          </Form.Item>
                        </Col>

                        <Col sm={12} xs={24}>
                          <Form.Item label="Pays" name="adresse_pays">
                            <Input
                              placeholder="Pays"
                              key={`${balise?.adresse?.adresse_pays}`}
                              defaultValue={balise?.adresse?.adresse_pays}
                            />
                          </Form.Item>
                        </Col>

                        <Col sm={12} xs={24}>
                          <Form.Item label="Ville" name="adresse_ville">
                            <Input
                              placeholder="Ville"
                              key={`${balise?.adresse?.adresse_ville}`}
                              defaultValue={balise?.adresse?.adresse_ville}
                            />
                          </Form.Item>
                        </Col>

                        <Col sm={24} xs={24}>
                          <Form.Item
                            label="Informations complémentaires"
                            name="adresse_points_remarquables"
                          >
                            <TextArea
                              placeholder="Informations complémentaires"
                              autoSize={true}
                              key={`${balise?.adresse?.adresse_points_remarquables}`}
                              defaultValue={
                                balise?.adresse?.adresse_points_remarquables
                              }
                            />
                          </Form.Item>
                        </Col>

                        <Divider>
                          <h3 style={{ margin: 0 }}>
                            Informations supplémentaires
                          </h3>
                        </Divider>
                        <Col sm={24} xs={24}>
                          <Form.Item
                            label="Informations personnelles"
                            name="info_personnelles"
                          >
                            <TextArea
                              placeholder="Informations personnelles"
                              autoSize={true}
                              key={`${balise?.info_personnelles}`}
                              defaultValue={balise?.info_personnelles}
                            />
                          </Form.Item>
                        </Col>
                        <Col sm={24} xs={24}>
                          <Form.Item
                            label="Informations techniques"
                            name="info_techniques"
                          >
                            <TextArea
                              placeholder="Informations techniques"
                              autoSize={true}
                              key={`${balise?.info_techniques}`}
                              defaultValue={balise?.info_techniques}
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Spacer />
                      <Form.Item>
                        <Button type="primary" htmlType="submit" block>
                          Enregistrer
                        </Button>
                      </Form.Item>
                    </Form>
                  </>
                ) : (
                  <Spin />
                )}
              </Card>
              <Spacer />
              <Card
                title={
                  <h2 style={{ margin: 0 }}>ID personnalisé de la balise</h2>
                }
              >
                <Input
                  value={id_perso}
                  onInput={(e) => this.setState({ id_perso: e.target.value })}
                  size="large"
                />
                <Spacer />

                <Button
                  type="primary"
                  block
                  onClick={() =>
                    updateBaliseIdPerso(balise_id, id_perso).then((_) =>
                      message.success(
                        "ID perso de la balise modifié avec succès"
                      )
                    )
                  }
                >
                  Enregistrer
                </Button>
              </Card>
            </Col>
          </Row>
        </CustomLayout>
      </div>
    );
  }
}

export default DetailBalise;
