import React, { Component } from "react";
import {
  Divider,
  Button,
  Row,
  Card,
  Col,
  Table,
  Modal,
  Form,
  Input,
  message,
  DatePicker,
} from "antd";
import CustomLayout from "../../components/CustomLayout";
import { Spacer, Spinner } from "../../components/global";
import { LeftOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import {
  addOrganisationUser,
  deleteOrganisationUser, exportOnUser,
  getOrganisation,
  getOrganisationBalises,
  getOrganisationUsers, reformatCsv,
  searchUserOrganisation,
} from "../../services/organisations.services";
import Moment from "react-moment";
import { createUser } from "../../services/users.services";
import Fuse from "fuse.js";

const { confirm } = Modal;

class DetailOrga extends Component {
  constructor(props) {
    super(props);

    this.formRef = React.createRef();

    this.state = {
      searchValue: "",
      loaded: false,
      modalAddUser: false,
      creationMode: false,
      exportFrom: '',
      exportTo: ''
    };
  }

  componentDidMount() {
    this.handleLoadInfos();
  }

  handleLoadInfos() {
    const organisation_id = this.props.match.params.id;
    getOrganisation(organisation_id).then((organisation) => {
      console.log(organisation);
      this.setState({ organisation_id, organisation });

      getOrganisationBalises(organisation_id).then((balises) => {
        console.log("balises", balises);
        this.setState({
          balises: balises.balises,
          constBalises: balises.balises,
        });
        this.fetchOrganisationUsers();
      });
    });
  }

  fetchOrganisationUsers() {
    const organisation_id = this.props.match.params.id;

    getOrganisationUsers(organisation_id).then((users) => {
      console.log("users", users);
      let users_id = [];
      users.users.forEach((user) => {
        users_id.push(user?.id);
      });
      this.setState({
        users: users.users,
        constUsers: users.users,
        users_id,
        loaded: true,
      });
    });
  }

  handleAddUser(user) {
    const organisation_id = this.props.match.params.id;

    addOrganisationUser(organisation_id, user?.id).then((_) => {
      const { users_id } = this.state;
      users_id.push(user?.id);
      this.setState({
        searchUsers: [],
        searchValue: "",
        users_id,
      });

      message.success("Utilisateur ajouté avec succès");
    });
  }

  handleDeleteUser(user_id) {
    const organisation_id = this.props.match.params.id;

    const onOk = () =>
      deleteOrganisationUser(organisation_id, user_id).then((_) => {
        message.success("Utilisateur supprimé de l'organisation");
        this.handleLoadInfos();
      });

    confirm({
      icon: <ExclamationCircleOutlined />,
      content: (
        <div>
          <h3>Êtes vous sûr de supprimer cet utilisateur ?</h3>
        </div>
      ),
      onOk,
      onCancel() {
        console.log("canceled");
      },
    });
  }

  handleCreateUser(body) {
    console.log(body);

    createUser(body).then((res) => {
      console.log("post", res);
      // this.handleAddUser()
    });

    this.formRef.current.resetFields();
    this.setState({ modalAddUser: false, creationMode: false });
  }

  handleSearchUser(query) {
    const organisation_id = this.props.match.params.id;
    this.setState({ searchValue: query });
    if (query.length > 2)
      searchUserOrganisation(organisation_id, query).then((res) =>
        this.setState({ searchUsers: res?.users })
      );
    else {
      this.setState({ searchUsers: [] });
    }
  }

  handleSearchBalise(value) {
    const { constBalises, balises } = this.state;

    if (value.length) {
      const fuse = new Fuse(balises, {
        keys: ["id_helpp_life"],
        includeScore: true,
        findAllMatches: true,
        threshold: 0.2,
      });

      var results = fuse.search(value);

      var balisesResults = [];
      if (results.length)
        results.forEach((result) => {
          balisesResults.push(result.item);
          this.setState({ balises: balisesResults });
        });
      else this.setState({ balises: [] });
    } else {
      this.setState({ balises: constBalises });
    }
  }

  searchActiveUser(value) {
    const { users, constUsers } = this.state;

    if (value.length) {
      const fuse = new Fuse(users, {
        keys: ["nom", "prenom", "id_helpp_life", "service", "date_naissance"],
        includeScore: true,
        findAllMatches: true,
        threshold: 0.1,
      });

      var results = fuse.search(value);
      console.log(results);

      if (results.length) {
        var new_users = [];
        results.map((result) => new_users.push(result.item));
        this.setState({ users: new_users });
      }
    } else this.setState({ users: constUsers });
  }

  render() {
    const {
      organisation_id,
      organisation,
      loaded,
      users,
      modalAddUser,
      creationMode,
      searchUsers,
      users_id,
      searchValue,
      balises,
    } = this.state;

    const isAdmin = new URLSearchParams(window.location.search).get("isAdmin");

    const users_col = [
      {
        title: "",
        dataIndex: "_id",
        key: "_id",
        hidden: true,
      },
      {
        title: "ID",
        dataIndex: "id",
        key: "id",
      },
      {
        title: "Nom",
        dataIndex: "nom",
        key: "nom",
      },
      {
        title: "Prénom",
        dataIndex: "prenom",
        key: "prenom",
      },

      {
        title: "Service",
        dataIndex: "service",
        key: "service",
      },
      {
        title: "Date de naissance",
        dataIndex: "date_naissance",
        key: "date_naissance",
        render: (date_naissance) => (
          <Moment format="DD/MM/YYYY">{date_naissance}</Moment>
        ),
      },
      {
        title: "",
        dataIndex: "actions",
        key: "actions",
        responsive: ["sm"],

        render: (_, record) => (
          <>
            <Row gutter={[24, 10]}>
              {isAdmin && (
                <Button
                  onClick={() =>
                    this.props.history.push(
                      `/organisations/${organisation_id}/user/${record._id}`
                    )
                  }
                >
                  Détails
                </Button>
              )}

              <Button
                danger={true}
                onClick={() => this.handleDeleteUser(record._id)}
                style={{
                  display: isAdmin === "true" ? "block" : "none",
                  marginLeft: 10,
                }}
              >
                Supprimer de l'organisation
              </Button>
            </Row>
          </>
        ),
      },
    ].filter((v) => !v.hidden);

    const balises_col = [
      {
        title: "",
        dataIndex: "_id",
        key: "_id",
        hidden: true,
      },
      {
        title: "ID Helpp-Life",
        dataIndex: "id_helpp_life",
        key: "id_helpp_life",
        width: 400,
      },
      {
        title: "Service",
        dataIndex: "service",
        key: "service",
      },
      {
        title: "Statut",
        dataIndex: "statut",
        key: "statut",
      },
      {
        title: "Adresse",
        dataIndex: "adresse",
        key: "adresse",
      },
    ].filter((v) => !v.hidden);

    return (
      <div>
        <CustomLayout {...this.props} selectedKey="users">
          {loaded ? (
            <>
              <Modal
                title="Utilisateurs de l'organisation"
                visible={modalAddUser}
                footer={
                  <Button
                    onClick={() => this.setState({ modalAddUser: false })}
                  >
                    Annuler
                  </Button>
                }
                onCancel={() => this.setState({ modalAddUser: false })}
              >
                {creationMode ? (
                  <>
                    <Form
                      layout="vertical"
                      onFinish={this.handleCreateUser.bind(this)}
                      ref={this.formRef}
                    >
                      <Form.Item name="nom" label="Nom">
                        <Input placeholder="Nom" />
                      </Form.Item>
                      <Form.Item name="prenom" label="Prénom">
                        <Input placeholder="Prénom" />
                      </Form.Item>
                      <Form.Item name="email" label="Adresse email">
                        <Input placeholder="Adresse e-mail" type="email" />
                      </Form.Item>
                      <Form.Item>
                        <Button block htmlType="submit" type="primary">
                          Créer
                        </Button>
                      </Form.Item>
                    </Form>
                    <Divider />
                    <Button
                      onClick={() => this.setState({ creationMode: false })}
                      block
                    >
                      Je souhaite ajouter un utilisateur existant
                    </Button>
                  </>
                ) : (
                  <>
                    <Input.Search
                      placeholder="Helpp ID de l'utilisateur"
                      onInput={(e) => this.handleSearchUser(e.target.value)}
                      value={searchValue}
                    />

                    {searchUsers?.map((user) => {
                      if (!users_id.includes(user.id)) {
                        return (
                          <div>
                            <Divider />
                            <Row
                              key={user?.id}
                              align="middle"
                              justify="space-between"
                            >
                              <p style={{ fontSize: 15, fontWeight: 500 }}>
                                {user?.nom + " " + user?.prenom}
                              </p>
                              <Button
                                size="small"
                                onClick={() => this.handleAddUser(user)}
                              >
                                Ajouter
                              </Button>
                            </Row>
                          </div>
                        );
                      } else {
                        return <></>;
                      }
                    })}

                    {/* <Divider />
                    <Button
                      onClick={() => this.setState({ creationMode: true })}
                      block
                    >
                      Je souhaite créer un utilisateur
                    </Button> */}
                  </>
                )}
              </Modal>
              <Row justify="start" align="middle" gutter={[6, 12]}>
                <Button
                  shape="circle"
                  icon={<LeftOutlined />}
                  onClick={() => this.props.history.goBack()}
                />
                <h1 style={{ margin: 0, marginLeft: 20 }}>
                  Organisation {organisation_id}
                </h1>
              </Row>
              <Divider />
              <Spacer />
              <Row gutter={[24, 12]}>
                <Col md={24} lg={6} xl={6}>
                  <Card title={<h2 style={{ margin: 0 }}>Détails</h2>}>
                    <h4>
                      Nom: <b>{organisation?.nom}</b>
                    </h4>
                    <h4>
                      Adresse:{" "}
                      <b>
                        {organisation?.adresse?.adresse_numero +
                          " " +
                          organisation?.adresse?.adresse_rue +
                          " " +
                          organisation?.adresse?.adresse_code_postal +
                          " " +
                          organisation?.adresse?.adresse_ville +
                          " " +
                          organisation?.adresse?.adresse_pays}
                      </b>
                    </h4>
                    <h4>
                      Commentaire: <b>{organisation?.nom}</b>
                    </h4>
                    <Divider />
                  </Card>
                  <Spacer />
                  <Card title="Veuillez sélectionner une période">
                    <DatePicker.RangePicker
                      style={{ width: "100%" }}
                      size="large"
                      onChange={(dates, dateStrings) => {
                        if (dates) {
                          this.setState({
                            exportFrom:  dateStrings[0].replaceAll('-', ''),
                            exportTo:  dateStrings[1].replaceAll('-', '')
                          });
                        }
                      }}
                    />
                    <Spacer />

                    <Button block size="large" onClick={async () => {
                      if (this.state.exportFrom && this.state.exportTo) {
                        let userList = [];
                        for (const user of users) {
                          userList.push(user.id_helpp_life);
                        }

                        let exports = await exportOnUser(userList, this.state.exportFrom, this.state.exportTo);
                        exports = reformatCsv(exports.toString());
                        const BOM = new Uint8Array([0xEF,0xBB,0xBF]);
                        let href = URL.createObjectURL(new Blob([BOM, exports],{type:'text/csv;charset=UTF-8', encoding:"UTF-8"}));
                        const link = document.createElement('a');
                        link.href = href;
                        link.download = `export-${organisation_id}-${this.state.exportFrom}-${this.state.exportTo}.csv`;
                        link.target = '_blank';
                        link.click();
                      }
                    }}>
                      Export QR codes
                    </Button>
                  </Card>
                </Col>

                <Col md={24} lg={18} xl={18}>
                  <Card
                    title={
                      <Row justify="space-between" align="middle">
                        <h2 style={{ margin: 0 }}>
                          Utilisateurs de cette organisation
                        </h2>
                        <Button
                          onClick={() => this.setState({ modalAddUser: true })}
                        >
                          Ajouter
                        </Button>
                      </Row>
                    }
                  >
                    <Input.Search
                      placeholder="Rechercher des utilisateurs dans l'organisation"
                      style={{ maxWidth: 500 }}
                      onInput={(e) => this.searchActiveUser(e.target.value)}
                    />
                    <Spacer />
                    <Table
                      scroll={{ x: 600 }}
                      columns={users_col}
                      dataSource={users?.map((user) => {
                        return {
                          key: user?.id,
                          _id: user?.id,
                          id: user?.id_helpp_life,
                          nom: user?.nom,
                          prenom: user?.prenom,
                          service: user?.service,
                          date_naissance: user?.date_naissance,
                        };
                      })}
                    />
                  </Card>
                </Col>
                <Col span={24}>
                  <Card
                    title={
                      <Row justify="space-between" align="middle">
                        <h2 style={{ margin: 0 }}>
                          Balises de cette organisation
                        </h2>
                      </Row>
                    }
                  >
                    <Input.Search
                      placeholder="Rechercher une balise"
                      onInput={(e) => this.handleSearchBalise(e.target.value)}
                    />
                    <Spacer />
                    <Table
                      scroll={{ x: 600 }}
                      columns={balises_col}
                      dataSource={balises?.map((balise) => {
                        const {
                          adresse_numero,
                          adresse_rue,
                          adresse_code_postal,
                          adresse_ville,
                          adresse_pays,
                        } = balise?.adresse;
                        const filtered_address =
                          adresse_numero +
                          " " +
                          adresse_rue +
                          " " +
                          adresse_code_postal +
                          " " +
                          adresse_ville +
                          " " +
                          adresse_pays;

                        return {
                          key: balise?.id,
                          _id: balise?.id,
                          id_helpp_life: balise?.id_helpp_life,
                          statut: balise?.statut_commercial,
                          service: balise?.etat,
                          adresse:
                            balise?.appkey.length > 0
                              ? filtered_address
                              : balise?.adresse,
                        };
                      })}
                    />
                  </Card>
                </Col>
              </Row>
            </>
          ) : (
            <Spinner />
          )}
        </CustomLayout>
      </div>
    );
  }
}

export default DetailOrga;
