import { Button, Card, Form, Input, message } from "antd";
import React, { Component } from "react";
import styled from "styled-components";
import Logo from "../../assets/images/logo.png";
import { firstLoginUser } from "../../services/auth.services";

class FirstLogin extends Component {
  onFinish(values) {
    console.log(values);

    if (values.password !== values.confirm_password)
      message.error("Les mots de passe ne correspondent pas");
    else
      firstLoginUser(values).then((_) => {
        message.success("Vos identifiants on été mis à jour avec succès");
        message.info(
          "Veuillez vous reconnecter avec vos nouveaux identifiants"
        );
        this.props.history.replace("/login");
      });
  }

  render() {
    return (
      <div>
        <LoginWrapper>
          <CardWrapper>
            <Card
              title={
                <div>
                  <center>
                    {" "}
                    <img src={Logo} alt="" style={{ height: 60, margin: 0 }} />
                    <br />
                    <h1 style={{ margin: 0 }}>Première connexion</h1>
                  </center>
                </div>
              }
            >
              <div style={{ padding: "0 10%" }}>
                <Form onFinish={this.onFinish.bind(this)}>
                  <Form.Item name="email" labe="Email" required={true}>
                    <Input
                      size="large"
                      placeholder="exemple@email.com"
                      type="email"
                      required
                    />
                  </Form.Item>
                  <Form.Item
                    name="password"
                    labe="Nouveau mot de passe"
                    required={true}
                  >
                    <Input
                      size="large"
                      placeholder="Nouveau mot de passe"
                      type="password"
                      required
                    />
                  </Form.Item>
                  <Form.Item
                    name="confirm_password"
                    labe="Confirmer le nouveau mot de passe"
                    required={true}
                  >
                    <Input
                      size="large"
                      placeholder="Confirmer le nouveau mot de passe"
                      type="password"
                      required
                    />
                  </Form.Item>
                  <Form.Item>
                    <Button size="large" block type="primary" htmlType="submit">
                      Connexion
                    </Button>
                  </Form.Item>
                </Form>
              </div>
            </Card>
          </CardWrapper>
        </LoginWrapper>
      </div>
    );
  }
}

export default FirstLogin;

const LoginWrapper = styled.div`
  background: #2c3e50;
  position: relative;
  padding: 20px;
  height: ${window.innerHeight}px;
  color: white;

  h1 {
      text-align: center;
      font-weight 800;
      margin: 0
  }
`;

const CardWrapper = styled.div`
  position: absolute;
  width: 70%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;
