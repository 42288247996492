import {
  Button,
  Card,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  message,
  Row,
} from "antd";
import React, { Component } from "react";
import CustomLayout from "../../components/CustomLayout";
import { Spacer } from "../../components/global";
import {exportOnUser, reformatCsv} from "../../services/organisations.services";
import {
  getProfile,
  updateEmail,
  updatePassword,
} from "../../services/users.services";

class SettingsPage extends Component {
  state = { user: {}, exportFrom: "", exportTo: "" };

  componentDidMount() {
    this.fetchProfile();
  }

  fetchProfile = async () => {
    await getProfile().then(({ user }) => {
      if (user)
        this.setState({ user }, () => {
          console.log(user);
        });
    });
  };

  handleEditEmail = async (values) => {
    const { email, confirmEmail } = values;

    if (email !== confirmEmail)
      message.error("Les adresses emails ne correspondent pas");
    else {
      updateEmail(email).then((res) => {
        if (res.status !== 500 || res.status !== 401 || res.status !== 404) {
          this.fetchProfile();
          message.success("Adresse email modifiée avec succès");
        } else {
          message.error(
            "Une erreur est survenue, veuillez réessayer ultérieurement"
          );
        }
      });
    }
  };

  handleEditPassword(values) {
    const { password, confirmPassword } = values;
    if (password !== confirmPassword)
      message.error("Les mots de passes ne correspondent pas");
    else {
      updatePassword(password).then((_) => {
        message.success("Mot de passe modifié avec succès", 1, () =>
          window.location.reload()
        );
      });
    }
  }

  render() {
    const { user } = this.state;
    return (
      <div>
        <CustomLayout {...this.props} selectedKey="settings">
          <h1>Paramètres</h1>
          <Divider />
          <Row gutter={16}>
            <Col sm={12} xs={24}>
              <Card title="Adresse email">
                <Input
                  type="email"
                  placeholder="Adresse email"
                  disabled={true}
                  value={this.state?.user?.email}
                />
                <Divider />

                <Form onFinish={this.handleEditEmail} layout="vertical">
                  <Form.Item
                    label="Nouvelle adresse email"
                    name="email"
                    rules={[
                      {
                        required: true,
                        message: "Veuillez renseigner une adresse email",
                      },
                    ]}
                  >
                    <Input type="email" placeholder="Adresse email" />
                  </Form.Item>
                  <Form.Item
                    label="Confirmer l'adresse email"
                    name="confirmEmail"
                    rules={[
                      {
                        required: true,
                        message: "Veuillez confirmer l'adresse email",
                      },
                    ]}
                  >
                    <Input
                      type="email"
                      placeholder="Confirmer la nouvelle adresse email"
                    />
                  </Form.Item>

                  <Form.Item>
                    <Button htmlType="submit" block>
                      Mettre à jour l'email
                    </Button>
                  </Form.Item>
                </Form>
              </Card>
            </Col>

            <Col sm={12} xs={24}>
              <Card title="Mot de passe">
                <Form layout="vertical" onFinish={this.handleEditPassword}>
                  <Form.Item
                    label="Modifier le mot de passe"
                    name="password"
                    rules={[
                      {
                        required: true,
                        message: "Veuillez entrer un mot de passe",
                      },
                    ]}
                  >
                    <Input.Password placeholder="Mot de passe" />
                  </Form.Item>
                  <Form.Item
                    label="Confirmer le mot de passe"
                    name="confirmPassword"
                    rules={[
                      {
                        required: true,
                        message: "Veuillez confirmer le mot de passe",
                      },
                    ]}
                  >
                    <Input.Password placeholder="Confirmer mot de passe" />
                  </Form.Item>
                  <Form.Item>
                    <Button block htmlType="submit">
                      Modifier le mot de passe
                    </Button>
                  </Form.Item>
                </Form>
              </Card>
            </Col>
          </Row>
          {user.type_compte === "2" && (
            <>
              <Spacer />
              <Divider orientation="left">
                <h2 style={{ margin: 0 }}>Export de QR Code</h2>
              </Divider>

              <Card title="Veuillez sélectionner une période">
                <DatePicker.RangePicker
                  style={{ width: "100%" }}
                  size="large"
                  onChange={(dates, dateStrings) => {
                    if (dates) {
                      this.setState({
                        exportFrom:  dateStrings[0].replaceAll('-', ''),
                        exportTo:  dateStrings[1].replaceAll('-', '')
                      });
                    }
                  }}
                />
                <Spacer />
                <center>
                  <Button
                    size="large"
                    onClick={async () => {
                      if (this.state.exportFrom && this.state.exportTo) {
                        let exports = await exportOnUser([this.state?.user?.id_helpp_life], this.state.exportFrom, this.state.exportTo);
                        exports = reformatCsv(exports.toString());
                        const BOM = new Uint8Array([0xEF,0xBB,0xBF]);
                        let href = URL.createObjectURL(new Blob([BOM, exports],{type:'text/csv;charset=UTF-8', encoding:"UTF-8"}));
                        const link = document.createElement('a');
                        link.href = href;
                        link.download = `export-${this.state?.user?.id_helpp_life}-${this.state.exportFrom}-${this.state.exportTo}.csv`;
                        link.target = '_blank';
                        link.click();
                      }
                    }}
                  >
                    Exporter QR codes scannés
                  </Button>
                </center>
              </Card>
            </>
          )}
        </CustomLayout>
      </div>
    );
  }
}

export default SettingsPage;
