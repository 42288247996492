import { Spin } from "antd";
import styled from "styled-components";

export const Spacer = styled.div`
  height: ${(props) => props.height ?? "25px"};
  opacity: 0;
`;

export const Spinner = () => {
  return (
    <div
      style={{
        position: "relative",
        width: "100%",
        height: window.innerHeight * 0.7,
      }}
    >
      <div
        style={{
          position: "absolute",
          left: "50%",
          top: "50%",
          transform: "translate(-50%)",
        }}
      >
        <Spin />
      </div>
    </div>
  );
};

export const renderAddress = (adresse) => {
  const {
    adresse_numero,
    adresse_rue,
    adresse_code_postal,
    adresse_ville,
    adresse_pays,
  } = adresse;

  return (
    adresse_numero +
    " " +
    adresse_rue +
    " " +
    adresse_code_postal +
    " " +
    adresse_ville +
    " " +
    adresse_pays
  );
};
