import React, { Component } from "react";
import styled from "styled-components";
import { Divider, Layout, Menu } from "antd";
import {
  UserOutlined,
  LogoutOutlined,
  WarningOutlined,
  ApartmentOutlined,
  QrcodeOutlined,
  FolderOpenOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import { Link } from "react-router-dom";
import BrandLogo from "../assets/images/logo.png";

const { Sider, Content, Header } = Layout;

class CustomLayout extends Component {
  state = {
    collapsed: false,
    isBroke: false,
  };

  toggle = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  };

  componentDidMount() {
    if (!localStorage.getItem("accessToken")) {
      this.props.history.replace("/login");
    }
  }

  render() {
    const { collapsed, isBroke } = this.state;

    return (
      <Layout
        style={{
          minHeight: window.innerHeight,
        }}
      >
        <Sider
          className="scrollbar-hidden"
          collapsible
          collapsed={collapsed}
          onCollapse={this.toggle}
          style={{
            overflow: "auto",
            height: "100%",
            position: "fixed",
            left: 0,
          }}
          //
          breakpoint="lg"
          collapsedWidth="0"
          onBreakpoint={(broken) => {
            this.setState({ isBroke: broken });
          }}
        >
          <center>
            <CustomImage src={BrandLogo} alt="Helpp-Life" />
          </center>
          <Divider
            style={{
              margin: 0,
              marginBottom: "20px",
            }}
          />

          <Menu
            theme="dark"
            mode="inline"
            defaultSelectedKeys={[this.props.selectedKey ?? "0"]}
            defaultOpenKeys={[this.props.openKey ?? "sub0"]}
            style={{ height: "100%", borderRight: 0, fontSize: "16px" }}
          >
            <Menu.Item key="tags" icon={<QrcodeOutlined />}>
              <Link to="/mon-tag">Mon Tag</Link>
            </Menu.Item>
            <Menu.Item key="balises" icon={<WarningOutlined />}>
              <Link to="/balises">Mes balises</Link>
            </Menu.Item>

            <Menu.Item key="profile" icon={<UserOutlined />}>
              <Link to="/profile">Mon profil</Link>
            </Menu.Item>

            <Menu.Item key="dossier_medical" icon={<FolderOpenOutlined />}>
              <Link to="/dossier_medical">Dossier Médical</Link>
            </Menu.Item>

            <Menu.Item key="organisations" icon={<ApartmentOutlined />}>
              <Link to="/organisations">Organisations</Link>
            </Menu.Item>

            <Menu.Item key="settings" icon={<SettingOutlined />}>
              <Link to="/settings">Paramètres</Link>
            </Menu.Item>

            <Divider style={{}} />
            <Menu.Item
              style={{ color: "red" }}
              key="logout"
              icon={<LogoutOutlined />}
            >
              <Link
                to="/login"
                style={{ color: "red" }}
                onClick={() => localStorage.removeItem("accessToken")}
              >
                Déconnexion
              </Link>
            </Menu.Item>
          </Menu>
        </Sider>
        <Layout
          style={{
            height: "100%",
            marginLeft: this.state.collapsed ? 0 : 200,
            transition: "all 0.2s ease-in-out",
          }}
        >
          {isBroke && (
            <Header>
              <Menu
                inlineIndent={0}
                theme="dark"
                mode="horizontal"
                defaultSelectedKeys={[this.props.selectedKey ?? "0"]}
              >
                <Menu.Item key="tags">
                  <Link to="/mon-tag">
                    <QrcodeOutlined />
                  </Link>
                </Menu.Item>

                <Menu.Item key="balises">
                  <Link to="/balises">
                    <WarningOutlined />
                  </Link>
                </Menu.Item>

                <Menu.Item key="profile">
                  <Link to="/profile">
                    <UserOutlined />
                  </Link>
                </Menu.Item>

                <Menu.Item key="dossier_medical">
                  <Link to="/dossier_medical">
                    <FolderOpenOutlined />
                  </Link>
                </Menu.Item>

                <Menu.Item key="organisations">
                  <Link to="/organisations">
                    <ApartmentOutlined />
                  </Link>
                </Menu.Item>

                <Menu.Item key="settings">
                  <Link to="/settings">
                    <SettingOutlined />
                  </Link>
                </Menu.Item>

                <Menu.Item style={{ color: "red" }} key="logout">
                  <Link
                    to="/login"
                    style={{ color: "red" }}
                    onClick={() => localStorage.removeItem("accessToken")}
                  >
                    <LogoutOutlined />
                  </Link>
                </Menu.Item>
              </Menu>
            </Header>
          )}
          <Content
            style={{
              margin: 0,
              padding: 30,
              minHeight: window.innerHeight,
              maxHeight: "100%",
            }}
          >
            <ChildWrapper>{this.props.children}</ChildWrapper>
          </Content>
        </Layout>
      </Layout>
    );
  }
}

export default CustomLayout;

const CustomImage = styled.img`
  height: 50px;
  margin: 20px auto;
  border-radius: 10px;
`;

const ChildWrapper = styled.div`
  max-height: 100%;
  padding-bottom: 50px;
  h1 {
    font-size: 36px;
    font-weight: 800;
    color: ${(props) => (props.darkMode ? "#fff" : "#000")} !important;
  }
  h2 {
    font-size: 28px;
    font-weight: 700;
    color: ${(props) => (props.darkMode ? "#fff" : "#000")} !important;
  }
  h3 {
    font-size: 24px;
    font-weight: 600;
    color: ${(props) => (props.darkMode ? "#fff" : "#000")} !important;
  }
  p {
    font-size: 15px;
    font-weight: 400;
    color: ${(props) => (props.darkMode ? "white" : "black")};
  }
`;
