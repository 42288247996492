import axios from "axios";
import { API_URL, SOCIETE_URL } from "../config";
import { catchError } from "./response";

const getApiRequestService = (baseURL?: string) => {
  return axios.create({
    baseURL: `${baseURL ?? API_URL}`,
    headers: {
      "Content-Type": "application/json",
      Authorization: ` ${
        localStorage.getItem("accessToken") !== null &&
        `Bearer ${localStorage.getItem("accessToken")}`
      }`,
    },
  });
};

const getApiRequestServiceNoCache = (baseURL?: string) => {
  return axios.create({
    baseURL: `${baseURL ?? API_URL}`,
    headers: {
      "Cache-Control": "no-cache",
      Pragma: "no-cache",
      Expires: "0",
      Authorization: ` ${
        localStorage.getItem("accessToken") !== null &&
        `Bearer ${localStorage.getItem("accessToken")}`
      }`,
    },
  });
};

// USERS REQUEST...
export const getProfile = async () => {
  try {
    const res = await getApiRequestService().get(`/users/profil`);

    return res.data;
  } catch (error) {
    console.log("GET_PROFILE", error);

    return catchError(error);
  }
};

export const editProfile = async (data: any) => {
  try {
    const res = await getApiRequestService().put(`/users/profil`, data);

    return res.data;
  } catch (error) {
    console.log("EDIT_PROFILE", error);

    return catchError(error);
  }
};

// MEDICAL FOLDER...
export const getMedicalFolder = async () => {
  try {
    const res = await getApiRequestService().get(`/users/dossier-medical`);

    return res.data;
  } catch (error) {
    console.log("GET_MEDICAL_FOLDER", error);

    return catchError(error);
  }
};

export const editMedicalFolder = async (data: any) => {
  try {
    const res = await getApiRequestService().put(
      `/users/dossier-medical`,
      data
    );

    return res.data;
  } catch (error) {
    console.log("EDIT_MEDICAL_FOLDER", error);

    return catchError(error);
  }
};

export const getMedicalFolderPhoto = async (index: number) => {
  try {
    const res = await getApiRequestServiceNoCache().get(
      `/users/dossier-medical/photo/${index}`,
      { responseType: "blob" }
    );

    const file_url = URL.createObjectURL(new File([res.data], "qrcode-png"));

    console.log(file_url);

    return file_url;
  } catch (error) {
    console.log("GET_MEDICAL_FOLDER_PHOTO", error);

    return catchError(error);
  }
};

export const getTag = async () => {
  try {
    const res = await axios
      .create({
        baseURL: "https://api.societe.helpp-life.fr/api/client",
        headers: {
          "Cache-Control": "no-cache",
          Pragma: "no-cache",
          Expires: "0",
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      })
      .get(`/users/dossier-medical/photo/0`);

    return res.data;
  } catch (error) {
    console.log("GET_TAG", error);
    return catchError(error);
  }
};

// Create user in db

export const createUser = async (data: any) => {
  try {
    const res = await getApiRequestService(SOCIETE_URL).post(`/users`, data);

    return res.data;
  } catch (error) {
    console.log("CREATE_USER", error);

    return catchError(error);
  }
};

// Get user from api societe

export const getUser = async (user_id: string) => {
  try {
    const res = await getApiRequestService(SOCIETE_URL).get(
      `/users/${user_id}`
    );

    return res.data;
  } catch (error) {
    console.log("GET_USER", error);

    return catchError(error);
  }
};

// Envoie d'email...
export const sendEmailUser = async (user_id: any, message: string) => {
  const data = { message };

  try {
    const res = await getApiRequestService(SOCIETE_URL).post(
      `/users/${user_id}/sendEmail`,
      data
    );

    return res.data;
  } catch (error) {
    console.log("SEND_EMAIL", error);

    return catchError(error);
  }
};

// Pour mettre à jour l'email
export const updateEmail = async (email: string) => {
  const data = { email };

  try {
    const res = await getApiRequestService(API_URL).put("/users/email", data);

    return res.data;
  } catch (error) {
    console.log("UPDATE_EMAIL", error);

    return catchError(error);
  }
};

// Pour mettre à jour le mot de passe
export const updatePassword = async (password: string) => {
  const data = { password, confirm_password: password };

  try {
    const res = await getApiRequestService(API_URL).put(
      "/users/password",
      data
    );

    return res.data;
  } catch (error) {
    console.log("UPDATE_PASSWORD", error);

    return catchError(error);
  }
};

// Pour mettre à jour la photo de profil
export const updateProfilePicture = async (data: FormData) => {
  try {
    const res = await axios
      .create({
        baseURL: `${API_URL}`,
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: ` ${
            localStorage.getItem("accessToken") !== null &&
            `Bearer ${localStorage.getItem("accessToken")}`
          }`,
        },
      })
      .post("/users/dossier-medical/photo/4", data);

    return res.data;
  } catch (error) {
    console.log("UPDATE_PHOTO_PROFIL", error);

    return catchError(error);
  }
};

// Pour mettre à jour le user
export const updateUserType = async (user_id: string, data: any) => {
  try {
    const res = await getApiRequestService(SOCIETE_URL).put(
      `/users/${user_id}`,
      data
    );

    return res.data;
  } catch (error) {
    console.log("UPDATE_USER_TYPE", error);

    return catchError(error);
  }
};
