import React, { Component } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "antd/dist/antd.css";
import "./App.css";
import frFR from "antd/lib/locale/fr_FR";
import "moment/locale/fr";
import { ConfigProvider } from "antd";
import HomePage from "./views/homePage/HomePage";
import SplashPage from "./views/static/SplashPage";
import ErrorPage from "./views/static/ErrorPage";
import LoginPage from "./views/authPages/loginPage";
import DetailBalise from "./views/homePage/DetailBalise";
import ResetPassword from "./views/authPages/resetPassword";
import QRPage from "./views/static/QRPage";
import OrgasPage from "./views/organisationsPage/OrgasPage";
import DetailOrga from "./views/organisationsPage/DetailOrga";
import TagPage from "./views/tagPage/TagPage";
import ProfilePage from "./views/usersPage/ProfilePage";
import MedicalPage from "./views/usersPage/MedicalPage";
import DetailOrgaUser from "./views/organisationsPage/DetailOrgaUser";
import FirstLogin from "./views/authPages/firstLogin";
import SettingsPage from "./views/settings/SettingsPage";

class App extends Component {
  render() {
    return (
      <ConfigProvider locale={frFR}>
        <Router>
          <Switch>
            <Route
              exact
              path="/balises"
              render={(props) => <HomePage {...props} />}
            />

            <Route
              exact
              path="/balises/detail/:id"
              render={(props) => <DetailBalise {...props} />}
            />

            <Route
              exact
              path="/mon-tag"
              render={(props) => <TagPage {...props} />}
            />
            <Route
              exact
              path="/profile"
              render={(props) => <ProfilePage {...props} />}
            />

            <Route
              exact
              path="/dossier_medical"
              render={(props) => <MedicalPage {...props} />}
            />

            <Route
              exact
              path="/organisations"
              render={(props) => <OrgasPage {...props} />}
            />
            <Route
              exact
              path="/organisations/:id"
              render={(props) => <DetailOrga {...props} />}
            />

            <Route
              exact
              path="/organisations/:organisation_id/user/:user_id"
              render={(props) => <DetailOrgaUser {...props} />}
            />

            <Route
              exact
              path="/settings"
              render={(props) => <SettingsPage {...props} />}
            />

            <Route
              exact
              path="/"
              render={(props) => <SplashPage {...props} />}
            />
            <Route
              exact
              path="/login"
              render={(props) => <LoginPage {...props} />}
            />

            <Route
              exact
              path="/login/first"
              render={(props) => <FirstLogin {...props} />}
            />

            <Route
              exact
              path="/reset-password"
              render={(props) => <ResetPassword {...props} />}
            />

            <Route
              exact
              path="/qrcode/:id"
              render={(props) => <QRPage {...props} />}
            />

            <Route render={(props) => <ErrorPage {...props} />} />
          </Switch>
        </Router>
      </ConfigProvider>
    );
  }
}
export default App;
